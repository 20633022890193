import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import { styled } from 'styled-components';

export const CustomiseLinkForm = styled(Form)`
  ${flex('column')};

  gap: 18px;

  .customise-link-view {
    &__title {
      ${fontSize(theme.fontSizes.FONT_20, '28px')};
      font-weight: 400;
      color: var(--SECONDARY_1_1);
      margin-top: ${theme.sizes.SIZE_16};
      margin-bottom: 14px;
    }

    &__name-container {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_4};

      &__root {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 600;
        color: var(--SHADE_1_1);

        ${flex('row', 'center', 'center')};

        width: ${theme.sizes.SIZE_144};

        padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_16};

        border-radius: ${theme.sizes.SIZE_8};

        background-color: var(--BACKGROUND_2_1);
      }

      &__input {
        width: 100%;
      }
    }

    &__description-input {
      .textarea__container {
        width: 100%;
        textarea {
          height: ${theme.sizes.SIZE_112};
        }
      }
    }

    &__pre-approved-box {
      .wcl-checkbox__label {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 400;
        color: var(--SHADE_1_1);
      }
    }

    &__form-actions {
      margin-top: ${theme.sizes.SIZE_32};
    }
  }
`;
