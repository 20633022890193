import ViewsProvider, { View } from '../../../providers/Views';
import { InvitePeopleProvider } from '../../../store';
import InvitePeopleModal, {
  InvitePeopleModalProps,
} from './InvitePeopleModal.component';
import CustomiseLink from './views/CustomiseLink';
import InvitePeople from './views/InvitePeople';

export type InvitePeopleViews = 'invite-people' | 'customise-link';

const views = [
  {
    key: 'invite-people',
    component: InvitePeople,
  },
  {
    key: 'customise-link',
    component: CustomiseLink,
  },
] as View<InvitePeopleViews>[];

const InvitePeopleModalViewWrapper = (props: InvitePeopleModalProps) => {
  return (
    <ViewsProvider initialView="invite-people" views={views}>
      <InvitePeopleProvider organisationId={props.organisationId}>
        <InvitePeopleModal {...props} />
      </InvitePeopleProvider>
    </ViewsProvider>
  );
};

export default InvitePeopleModalViewWrapper;
