import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ReportContainer,
  useCallbackRef,
  MiniReportTotal,
  useUtilities,
  Heading,
  getColor,
} from '@faxi/web-component-library';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import classNames from 'classnames';

import {
  Icon,
  ActiveUsersChart,
  MessagesChart,
  VerificationsChart,
  CommunityElement,
  EarnedRewardsReport,
  InTheCarPark,
  ParkingSpaceAllocation,
} from 'components';
import { ReportsContext, UserContext } from 'store';
import { CarPark } from 'models';
import { ActiveUsersChartRef } from 'components/_charts/ActiveUsersChart/ActiveUsersChart.component';
import config from 'config';
import { apiCommunity } from 'modules';
import { useHeadTitle, useAbortController } from 'hooks';
import { INameExtended } from 'components/Icon';
import { PageLayout } from 'components/_layouts';

import * as Styled from './Dashboard.styles';
import InvitePeopleModalViewWrapper from 'components/_modals/InvitePeopleModal';

const Dashboard = (): JSX.Element => {
  const {
    kpiData,
    dateRange,
    numberOfMessagesTotal,
    numberOfTestMessagesTotal,
    loadingDistances,
    updateDateRange,
  } = useContext(ReportsContext);

  const { t } = useTranslation();
  useHeadTitle(t('header_dashboard'));

  const {
    community,
    includeTestUsers,
    premiumFeatureAvailable,
    isValidCommunity,
  } = useContext(UserContext);

  const { organisationId } = useParams() as { organisationId: string };

  const invitePeopleBtnRef = useRef<HTMLButtonElement>(null);

  const [activeUsersChart, activeUsersChartRef] =
    useCallbackRef<ActiveUsersChartRef>();

  const [invitePeopleModalActive, setInvitePeopleModalActive] = useState(false);
  const [carParks, setCarParks] = useState<CarPark[]>();

  const { showOverlay, hideOverlay } = useUtilities();

  const navigate = useNavigate();

  const numberOfVehicles = useMemo(
    () => carParks?.reduce((acc, el) => acc + el.takenPlacesCount, 0) || 0,
    [carParks]
  );

  const {
    isCancel,
    abortSignal: abortCarpakSignal,
    cancelPreviousRequest: cancelCarParksRequest,
  } = useAbortController();

  const getCarParks = useCallback(async () => {
    try {
      cancelCarParksRequest();

      const { rc, carparks: apiCarparks } = await apiCommunity.getCarParks(
        +organisationId,
        dayjs().format(config.apiDateFormat),
        { signal: abortCarpakSignal() }
      );
      if (rc === 'ok' && apiCarparks) {
        setCarParks(
          apiCarparks.map(({ name, place_total, place_total_taken }) => ({
            carParkName: name,
            totalPlacesCount: Number(place_total),
            takenPlacesCount: Number(place_total_taken),
          }))
        );
      }
    } catch (e) {
      if (!isCancel(e)) {
        console.error(e);
      }
    } finally {
      hideOverlay('#in-the-car-park');
    }
  }, [
    abortCarpakSignal,
    cancelCarParksRequest,
    hideOverlay,
    isCancel,
    organisationId,
  ]);

  useEffect(() => {
    if (!carParks) {
      showOverlay('#in-the-car-park');
    }

    return () => {
      hideOverlay('#in-the-car-park');
    };
  }, [carParks, hideOverlay, showOverlay]);

  // RANGE RESET TO LAST 30 DAYS
  useEffect(() => {
    if (!dateRange)
      updateDateRange({
        to: dayjs(),
        from: dayjs().subtract(29, 'day'),
      });
  }, [dateRange, t, updateDateRange]);

  useEffect(() => {
    if (!isValidCommunity) {
      return;
    }

    getCarParks();
  }, [isValidCommunity, getCarParks]);

  return (
    <PageLayout className={classNames('kinto-page', 'dashboard')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('header_dashboard')}
      </Heading>
      <div className="kinto-page__header">
        <CommunityElement />
      </div>
      <div className="kinto-page__body">
        {/* MINI REPORTS & INVITE PEOPLE */}
        <div className="kinto-page__body__report-banner">
          <div className="report-banner">
            <MiniReportTotal<INameExtended>
              className="report-banner__report"
              iconName="users"
              loading={
                !community ||
                typeof community.approved_users === 'undefined' ||
                (includeTestUsers &&
                  typeof community['approved_users-test'] === 'undefined')
              }
              total={`${community?.approved_users}`}
              hasTest={includeTestUsers}
              totalTest={`${community?.['approved_users-test'] || 0}`}
              text={t('approved_community_members')}
            />
            <MiniReportTotal<INameExtended>
              className="report-banner__report"
              type="green"
              iconName="leaf"
              loading={loadingDistances}
              total={`${kpiData?.co2Total}`}
              hasTest={includeTestUsers}
              totalTest={`${kpiData?.co2TotalTest || 0}`}
              text={parse(t('total_co2_savings_kg_unit'))}
              buttonId="go_to_environmental_co2_report"
              goToButtonAriaLabel={t('accessibility-button_go_to_destination', {
                destination: 'CO₂',
              })}
              onClick={() => {
                navigate({
                  pathname: '../reports',
                  hash: 'co2-chart',
                });
              }}
            />
          </div>
          <Styled.ImageContainer
            $url="/assets/images/dashboard-invite.png"
            className="kinto-image-container"
          >
            <div className="image-wrapper">
              <div className="image-wrapper__title">{t('invite_people')}</div>

              <div className="image-wrapper__text">
                {t('build_your_community_start', {
                  communityName: community?.name,
                })}
              </div>

              <Button
                ref={invitePeopleBtnRef}
                id="invite_people_dashboard"
                variant="primary-invert"
                icon={<Icon name="plus" />}
                onClick={() => setInvitePeopleModalActive(true)}
              >
                {t('invite_people')}
              </Button>
            </div>
          </Styled.ImageContainer>
        </div>

        {/* ACTIVE USERS */}
        <ReportContainer
          info={t('reports-short_info_active_users')}
          totalsTooltipContent={t('total_for_chosen_period')}
          title={t('active_users')}
          tooltipContent={t('active_users_graph').replace(/\\n/g, '<br />')}
          totals={[
            {
              number: activeUsersChart?.activeUsersTotal.total,
              testNumber: activeUsersChart?.activeUsersTotal.testTotal,
              hasTestData: includeTestUsers,
            },
          ]}
          actionButtonText={t('header_reports')}
          actionButtonId="go_to_active_users_report"
          onClickActionButton={() => {
            navigate({
              pathname: '../reports',
              hash: 'active-users-chart',
            });
          }}
        >
          <ActiveUsersChart ref={activeUsersChartRef} />
        </ReportContainer>

        {/* IN THE CAR PARK */}
        <ReportContainer
          title={t('in_the_car_park')}
          info={t('dashboard-short_info_in_the_carpark')}
          totalsTooltipContent={t('vehicles')}
          totals={[
            {
              number: numberOfVehicles,
              icon: 'car',
            },
          ]}
        >
          <InTheCarPark carParks={carParks} />
        </ReportContainer>

        {/* PARKING SPACE ALLOCATION */}
        <ReportContainer
          title={t('parking_spot_allocation')}
          info={t('parking_spot_allocation_description')}
        >
          <ParkingSpaceAllocation />
        </ReportContainer>

        {/* TABLE OF EARNERD REWARDS FOR TODAY */}
        {premiumFeatureAvailable('GAMIFICATION_FEATURE') && (
          <EarnedRewardsReport />
        )}

        {/* MESSAGES */}
        <ReportContainer
          info={t('reports-short_info_number_of_messages')}
          totalsTooltipContent={t('total_for_chosen_period')}
          totals={[
            {
              number: numberOfMessagesTotal,
              testNumber: numberOfTestMessagesTotal,
              hasTestData: includeTestUsers,
            },
          ]}
          title={t('number_of_messages')}
          tooltipContent={t('messages_graph').replace(/\\n/g, '<br />')}
          actionButtonText={t('header_reports')}
          actionButtonId="go_to_message_numbers_report"
          onClickActionButton={() => {
            navigate({
              pathname: '../reports',
              hash: 'messages-chart',
            });
          }}
        >
          <MessagesChart />
        </ReportContainer>

        {/* VERIFICATION */}
        <ReportContainer
          info={t('reports-chart_short_description_verifications')}
          totalsTooltipContent={t('total_for_chosen_period')}
          totals={[
            {
              number: Number(kpiData?.verificationsTotal),
              testNumber: Number(kpiData?.verificationsTotalTest),
              hasTestData: includeTestUsers,
            },
          ]}
          title={t('reports-chart_title_verifications')}
          tooltipContent={parse(
            t('reports-chart_long_description_verifications').replace(
              /\\n/g,
              '<br />'
            )
          )}
          actionButtonText={t('header_reports')}
          actionButtonId="go_to_verified_journeys_report"
          onClickActionButton={() => {
            navigate({
              pathname: '../reports',
              hash: 'verified-journeys-chart',
            });
          }}
        >
          <VerificationsChart />
        </ReportContainer>
      </div>

      {invitePeopleModalActive && (
        <InvitePeopleModalViewWrapper
          organisationId={+organisationId}
          triggerRef={invitePeopleBtnRef.current as HTMLElement}
          onClose={() => {
            setInvitePeopleModalActive(false);
          }}
        />
      )}
    </PageLayout>
  );
};

export default Dashboard;
