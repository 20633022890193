import {
  Heading,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { FormField, FormRef, validators } from '@faxi/web-form';
import { t } from 'i18next';
import { apiDeeplink } from 'modules/api';
import { DeepLinkProps } from 'modules/api/apiDeeplink';
import { useView } from 'providers/Views';
import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { InvitePeopleViews } from '../..';
import { InvitePeopleContext } from 'store';
import FormActions from 'components/_layouts/FormActions';
import CheckboxInfoField from 'components/_molecules/CheckboxInfoField';
import InputField from 'components/_molecules/InputField';
import TextareaField from 'components/_molecules/TextareaField';
import * as Styled from './CustomiseLink.styles';
import { DeepLinkData } from 'models/Organisation';

type CustomiseLinkFormData = Omit<DeepLinkProps, 'oid' | 'url'>;

export type CustomiseLinkProps = {
  organisationId: number;
};

const CustomiseLink: FC<CustomiseLinkProps> = (props) => {
  const { organisationId } = props;

  const { magicLink, setMagicLink } = useContext(InvitePeopleContext);

  const { pushView } = useView<InvitePeopleViews>();

  const [form, formRef] = useCallbackRef<FormRef>();

  const [FormButtons] = useFormButtons({
    cancelLabel: t('cancel'),
    submitLabel: t('CommuteDetails_saving_message'),
  });

  const initialData = useMemo(() => {
    if (!magicLink?.deeplink) return undefined;

    const { name, title, description, pre_approved } = magicLink.deeplink;

    return { name, title, description, pre_approved };
  }, [magicLink.deeplink]);

  const generateFormData = useCallback(
    (customizeLinkFormData: CustomiseLinkFormData) => {
      const formData = new FormData();
      formData.append('oid', organisationId.toString());

      formData.append('url', magicLink.code);
      formData.append(
        'name',
        customizeLinkFormData.name.trim().replace(/\s+/g, '_')
      );
      formData.append('title', customizeLinkFormData.title);
      formData.append('description', customizeLinkFormData.description);
      formData.append(
        'pre_approved',
        (+customizeLinkFormData.pre_approved).toString()
      );

      return formData;
    },
    [magicLink.code, organisationId]
  );

  const handleSubmit = useCallback(
    async (customizeLinkFormData: CustomiseLinkFormData) => {
      const request = magicLink?.deeplink
        ? apiDeeplink.overrideDeepLink
        : apiDeeplink.generateDeepLink;

      const formData = generateFormData(customizeLinkFormData);

      const {
        data,
      }: {
        data: {
          deeplink: DeepLinkData;
        };
      } = await request(organisationId, formData);
      setMagicLink((old) => ({
        ...old,
        deeplink: {
          ...data.deeplink,
          pre_approved: +data.deeplink.pre_approved,
        },
      }));
      pushView('invite-people');
    },
    [
      magicLink?.deeplink,
      generateFormData,
      organisationId,
      setMagicLink,
      pushView,
    ]
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('global-custom_name'),
          })
        ),
        validators.general.maxLength(
          64,
          t('validation-field_validation_max_length', {
            fieldname: t('global-custom_name').toLowerCase(),
            number: '64',
          })
        ),
      ],
      title: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('custom_link-label_link_title'),
          })
        ),
        validators.general.maxLength(
          128,
          t('validation-field_validation_max_length', {
            fieldname: t('custom_link-label_link_title').toLowerCase(),
            number: '128',
          })
        ),
      ],
      description: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('dw_description'),
          })
        ),
        validators.general.maxLength(
          255,
          t('validation-field_validation_max_length', {
            fieldname: t('dw_description').toLowerCase(),
            number: '255',
          })
        ),
      ],
    }),
    []
  );

  return (
    <Styled.CustomiseLinkForm
      initialData={initialData}
      strictValidation={false}
      className="customise-link-view"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <Heading level="2" className="customise-link-view__title">
        {t('global-customise_link')}
      </Heading>
      <div className="customise-link-view__name-container">
        <div className="customise-link-view__name-container__root">
          kintojoin.app.link
        </div>
        <FormField
          id="custom_name"
          name="name"
          type="text"
          component={InputField}
          placeholder={t('global-custom_name')}
          className="customise-link-view__name-container__input"
          validate={validations.name}
          autoComplete="on"
        />
      </div>
      <FormField
        id="link_title"
        name="title"
        type="text"
        component={InputField}
        placeholder={t('custom_link-label_link_title')}
        className="customise-link-view__title-input"
        validate={validations.title}
        autoComplete="on"
      />
      <FormField
        id="link_description"
        name="description"
        component={TextareaField}
        noresize
        placeholder={t('custom_link-label_link_description')}
        className="customise-link-view__description-input"
        validate={validations.description}
        autoComplete="on"
      />

      <FormField
        className="customise-link-view__pre-approved-box"
        id="pre_approved_box"
        name={'pre_approved'}
        component={CheckboxInfoField}
        label={t('invite-people_button_preapprove_into_community')}
        labelPosition="right"
      />

      <FormActions className="customise-link-view__form-actions">
        <FormButtons.Submit
          disabled={!form?.isFormChanged() || !form.syncFormValid}
        />

        <FormButtons.Cancel
          type="button"
          className="cancel-btn"
          aria-label="cancel-reward"
          onClick={() => pushView('invite-people')}
        />
      </FormActions>
    </Styled.CustomiseLinkForm>
  );
};

export default memo(CustomiseLink);
