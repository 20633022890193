import { theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  mobile,
  position,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const CopyLink = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${size('100%')};

  position: relative;
  gap: ${theme.sizes.SIZE_16};

  ${mobile(css`
    ${flex('column', 'center', 'flex-start')}
  `)}

  .kinto-copy-link {
    &__title {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};
      ${position('absolute', 'top 0 left 0')};

      margin: 0;
      white-space: nowrap;
      color: var(--SHADE_1_2);
      transform: translate3d(0, -100%, 0);
      margin-right: ${theme.sizes.SIZE_10};
    }

    &__content {
      ${flex('row', 'center', 'center')};

      width: 100%;
      cursor: pointer;
      min-height: ${theme.sizes.SIZE_48};
      max-width: ${theme.sizes.SIZE_520};
      border-radius: 27px;
      background-color: var(--BACKGROUND_2_1);
      padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_16};
      margin-right: ${theme.sizes.SIZE_2};

      &__edit-button {
        ${size(theme.sizes.SIZE_32)};

        min-height: unset;
        margin-left: auto;
      }

      &__invite {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        font-weight: 600;
        text-align: start;
        word-break: break-all;
        color: var(--SHADE_1_1);
        margin-right: ${theme.sizes.SIZE_10};
        width: 100%;

        &__spinner {
          margin: auto;
        }
      }
    }

    &__copy {
      white-space: nowrap;
    }
  }
`;
