import { createContext, Dispatch, SetStateAction } from 'react';
import { InviteCodes } from '../../models';

interface InvitePeopleContextInterface {
  qrCodeURL: string;
  magicLink: InviteCodes;
  fastCode?: InviteCodes;
  loadingGet: boolean;
  loadingUpdate: boolean;
  loadingJoinCode: boolean;
  //
  downloadPDF: (...args: any[]) => Promise<void>;
  setQrCodeUrl: Dispatch<SetStateAction<string>>;
  setFastCode: Dispatch<SetStateAction<InviteCodes>>;
  updateMagicLink: (...args: any[]) => Promise<void>;
  setMagicLink: Dispatch<SetStateAction<InviteCodes>>;
  handleChangeJoinCode: (...args: any[]) => Promise<void>;
}

export const invitePeopleDefaultValue: InvitePeopleContextInterface = {
  magicLink: {
    code: '',
  },
  qrCodeURL: '',
  loadingGet: false,
  fastCode: undefined,
  loadingUpdate: false,
  loadingJoinCode: false,
  //
  setFastCode: () => {},
  setQrCodeUrl: () => {},
  setMagicLink: () => {},
  downloadPDF: () => new Promise(() => {}),
  updateMagicLink: () => new Promise(() => {}),
  handleChangeJoinCode: () => new Promise(() => {}),
};

const InvitePeopleContext = createContext<InvitePeopleContextInterface>(
  invitePeopleDefaultValue
);

export default InvitePeopleContext;
