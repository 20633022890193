import classNames from 'classnames';
import { InfoCard } from 'components';
import Icon, { INameExtended } from 'components/Icon';
import { ButtonProps } from '@faxi/web-component-library';

import * as Styled from './ButtonCard.styles';

export type ButtonCardProps = {
  title: string;
  description?: string;
  icon?: INameExtended;
  className?: string;
  warning?: string;
  warningLink?: { text: string; link: string };
} & ButtonProps;

const ButtonCard = (props: ButtonCardProps) => {
  const { title, description, icon, className, warning, warningLink, ...rest } =
    props;

  return (
    <Styled.ButtonCard
      className={classNames(className)}
      variant="ghost"
      {...rest}
    >
      <div className="button-card__content">
        {icon && <Icon className="button-card__button-icon" name={icon} />}
        <div>
          <div className="button-card__title">{title}</div>
          <p className="button-card__description">{description}</p>
        </div>
        <Icon className="button-card__chevron-right" name="chevron-right" />
      </div>
      {warning && (
        <InfoCard
          orientation="vertical"
          text={warning}
          link={{
            label: warningLink?.text || '',
            linkProps: { to: warningLink?.link },
          }}
          className="button-card__warning-info-card"
        />
      )}
    </Styled.ButtonCard>
  );
};

export default ButtonCard;
