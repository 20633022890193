import httpClient from '../httpClient';

export type DeepLinkProps = {
  oid: string;
  url: string;
  name: string;
  title: string;
  description: string;
  pre_approved: number;
};

const generateDeepLink = async (oid: number, formData: FormData) => {
  return httpClient.post(`admin/${oid}/deeplink`, formData);
};

const overrideDeepLink = async (oid: number, formData: FormData) => {
  return httpClient.put(`admin/${oid}/deeplink`, formData);
};

export default {
  generateDeepLink,
  overrideDeepLink,
};
