import { FC } from 'react';
import { LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import {
  Button,
  ButtonProps,
  Link,
  useResize,
} from '@faxi/web-component-library';
import { responsiveSm } from '@faxi/web-css-utilities';
import Icon, { INameExtended } from 'components/Icon';

import * as Styled from './InfoCard.styles';

type InfoCardProps = {
  className?: string;
  text: string;
  icon?: INameExtended;
  button?: { label: string; buttonProps?: ButtonProps };
  link?: { label: string; linkProps?: Partial<LinkProps> };
  orientation?: 'horizontal' | 'vertical';
  variant?: 'warning' | 'announcement';
};

const InfoCard: FC<InfoCardProps> = (props) => {
  const {
    className,
    text,
    icon = 'triangle-exclamation',
    button,
    link,
    orientation = 'horizontal',
    variant = 'warning',
  } = props;

  const isBelowPhablet = useResize(responsiveSm);

  return (
    <Styled.InfoCardContainer
      className={classNames(
        'kinto-info-card',
        `kinto-info-card--${orientation}`,
        `kinto-info-card--${variant}`,
        { 'kinto-info-card--vertical': isBelowPhablet },
        className
      )}
    >
      <Icon name={icon} />

      <div className="kinto-info-card__content">
        <p className="kinto-info-card__text">{text}</p>
        {button && (
          <Button {...button.buttonProps} className="kinto-info-card__button">
            {button.label}
          </Button>
        )}
        {link && (
          <Link
            className="kinto-info-card__link"
            {...(link.linkProps as LinkProps)}
          >
            {link.label}
          </Link>
        )}
      </div>
    </Styled.InfoCardContainer>
  );
};

export default InfoCard;
