import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useRef,
  Fragment,
} from 'react';
import {
  Button,
  SortOption,
  SelectOption,
  useCallbackRef,
  Image,
  Table,
  useEffectSkipFirst,
  SORT_OPTIONS,
  TableRef,
  useQueryParams,
  Heading,
  PromptModalProps,
  StatusElement,
  Menu,
  ButtonProps,
  MenuRef,
  useUtilities,
  PAGE_LIMIT,
  getColor,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  InputField,
  Icon,
  CommunityElement,
  FiltersModal,
  ShiftElement,
} from 'components';

import { Filter } from 'components/_modals/FiltersModal/FiltersModal.component';
import { AuthContext, UserContext } from 'store';
import {
  useTablePagination,
  useCallbackAsync,
  useAbortController,
  useHeadTitle,
  useColumnSettings,
} from 'hooks';
import { ObjectValues, PeopleFilters, PeoplePageUser, Shift } from 'models';
import { apiPeople, apiPredefinedShifts, settingsTranslations } from 'modules';
import {
  containsAtLeastOneStatusType,
  containsOnlyStatus,
  getUserStatusElementStatus,
  getUserStatusElementTranslation,
} from 'utils';
import ShiftSettingsModal from 'components/_modals/ShiftSettings';
import { PageLayout } from 'components/_layouts';

import * as Styled from './People.styles';
import { NoData } from 'Global.styles';
import saveAs from 'file-saver';
import InvitePeopleModalViewWrapper from 'components/_modals/InvitePeopleModal';

dayjs.extend(advancedFormat);

export type ViewsPerPage = keyof typeof PAGE_LIMIT;

const INITIAL_PEOPLE_SORT = {
  sortBy: 'id',
  sortDirection: SORT_OPTIONS.DSC,
} as SortOption<PeoplePageUser>;

export enum CommunityActions {
  APPROVE,
  REJECT,
  DEACTIVATE,
  REACTIVATE,
  APPROVE_ONE_USER,
  REJECT_ONE_USER,
  DEACTIVATE_ONE_USER,
  REACTIVATE_ONE_USER,
  NONE,
}

const FILTER_TYPES = {
  BASIC_FILTERS: 'BASIC_FILTERS',
  SEARCH_FILTERS: 'SEARCH_FILTERS',
} as const;

export type FilterTypes = ObjectValues<typeof FILTER_TYPES>;

const splitAndFilterFilters = (filters?: string) => {
  if (!filters) return [];

  return filters.split(',').filter((s) => !!s);
};

const PeoplePage = (): JSX.Element => {
  const { sessionId } = useContext(AuthContext);
  const {
    userReady,
    userPreferences: { dateFormat, unit },
    communityId,
    community,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const { prompt, prompts, showSnackBar } = useUtilities();

  const { t } = useTranslation();
  useHeadTitle(t('mPeople'));

  const { params, setMultipleQueryParams } = useQueryParams<
    PeopleFilters & { page: string }
  >();

  // used for determining whether user array is expanding or
  // reassigning
  const lastLoadedUsers = useRef<PeoplePageUser[]>();
  const excludeColumns = useRef<(keyof PeoplePageUser)[]>([
    'first_name',
    'last_name',
    'profilepic_ts',
    'image_url',
    'authorised',
    'messages_count',
    'shifts',
    'is_admin',
  ]);

  const filterBtnRef = useRef<HTMLButtonElement>(null);
  const shiftSettingsRef = useRef<HTMLButtonElement>(null);
  const invitePeopleBtnRef = useRef<HTMLButtonElement>(null);

  const {
    columnBtnRef,
    columnTranslations,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const [invitePeopleModalActive, setInvitePeopleModalActive] = useState(false);

  const [shiftSettingsModal, setShiftSettingsModal] = useState<boolean>(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [shifts, setShifts] = useState<Shift[]>([]);

  const [table, tableRef] = useCallbackRef<TableRef>();
  const [editMenu, editMenuRef] = useCallbackRef<MenuRef>();

  const { checkedRowsData } = table || {};

  const {
    data,
    count,
    search,
    loading,
    totalPages,
    activeColumnSort,
    totalCount,
    currentPage,
    errorMessage,
    revalidate,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<PeoplePageUser, 'users'>({
    itemsKey: 'users',
    resetDeps: [communityId],
    deps: [communityId, sessionId, params],
    condition: userReady && !!Object.values(params).length,
    initialSortBy: INITIAL_PEOPLE_SORT.sortBy,
    initialSortDirection: INITIAL_PEOPLE_SORT.sortDirection,
    customErrorMessage: t(`no_members_in_community`),
    onReset: () => {
      table.setActiveSortFilter(
        INITIAL_PEOPLE_SORT as SortOption<Record<string, any>>
      );
    },
    mappingFunction: async (tableData: PeoplePageUser[]) => {
      const mappedUsers = tableData.map(
        ({
          id,
          email,
          first_name,
          last_name,
          lastactive_ts,
          joined_ts,
          authorised,
          shifts,
          image_url,
          co2,
          nox,
          no_journeys,
          distance,
          ...restUser
        }) =>
          ({
            id,
            name: (
              <div className="table-data-with-image">
                <Image
                  src={image_url || ''}
                  alt={t('user_profile_picture', { user: first_name })}
                  className="profile-img"
                  fallbackUrl="/assets/svg/user_circle_placeholder.svg"
                />
                {first_name || last_name
                  ? [first_name, last_name].join(' ').trim()
                  : '-'}
              </div>
            ),
            email,
            lastactive_ts: lastactive_ts
              ? dayjs(lastactive_ts).format(dateFormat)
              : '-',
            joined_ts: dayjs(joined_ts).format(dateFormat),
            shiftLabel: shifts ? (
              <ShiftElement shift={shifts?.[0]}>
                {shifts?.[0].name}
              </ShiftElement>
            ) : (
              '-'
            ),
            status: (
              <StatusElement status={getUserStatusElementStatus(authorised)}>
                {t(getUserStatusElementTranslation(authorised))}
              </StatusElement>
            ),
            image_url: image_url || '/assets/svg/user_circle_placeholder.svg',
            authorised,
            first_name,
            last_name,
            shifts,
            no_journeys,
            co2: `${(co2 as number).toFixed(2)}kg`,
            nox: `${(nox as number).toFixed(2)}kg`,
            distance: `${(distance as number).toFixed(2)}${unit}`,
            ...restUser,
          } as PeoplePageUser)
      );

      lastLoadedUsers.current = mappedUsers;

      return mappedUsers;
    },
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction,
      config
    ) =>
      apiPeople.getPeople({
        organisationId: communityId!,
        count,
        offset,
        search,
        unit,
        status: splitAndFilterFilters(params.status),
        transport_type: splitAndFilterFilters(params.transportType),
        role: splitAndFilterFilters(params.role),
        sort_by,
        sort_direction,
        config,
      }),
  });

  const assignedUsersShifts = useMemo(
    () =>
      (checkedRowsData as PeoplePageUser[])?.reduce(
        (currentValue, value) => ({
          ...currentValue,
          [value.id]: value.shifts,
        }),
        {}
      ),
    [checkedRowsData]
  );

  const checkedUsersIdsArray = useMemo(
    () => checkedRowsData?.map((user) => `${user.id}`),
    [checkedRowsData]
  );

  const checkedUsersStatuses = useMemo(
    () => checkedRowsData?.map((el) => el['authorised']) || [],
    [checkedRowsData]
  );

  const someApprovedCheckedUsersAreNotAdmins = useMemo(
    () =>
      checkedRowsData?.some(
        ({ is_admin, authorised }) => authorised === 'Y' && is_admin === 'N'
      ),
    [checkedRowsData]
  );

  const someCheckedUsersAreAdmins = useMemo(
    () => checkedRowsData?.some(({ is_admin }) => is_admin === 'Y'),
    [checkedRowsData]
  );

  const hasAtLeastOneApprovedChecked = useMemo(
    () => containsAtLeastOneStatusType(checkedUsersStatuses, 'Y'),
    [checkedUsersStatuses]
  );

  const hasAtLeastOnePendingChecked = useMemo(
    () => containsAtLeastOneStatusType(checkedUsersStatuses, 'N'),
    [checkedUsersStatuses]
  );

  const hasOnlyRejectedChecked = useMemo(
    () => containsOnlyStatus(checkedUsersStatuses, 'R'),
    [checkedUsersStatuses]
  );

  const hasAtLeastOneDeactivatedChecked = useMemo(
    () => containsAtLeastOneStatusType(checkedUsersStatuses, 'D'),
    [checkedUsersStatuses]
  );

  const filters = useMemo<Filter[]>(
    () => [
      {
        legendLabel: t('people-filter_label_requests'),
        name: 'status',
        checkboxes: [
          {
            label: t('approved'),
            value: 'accepted',
            id: 'filter_people_status_approved',
          },
          {
            label: t('fc_pending'),
            value: 'pending',
            id: 'filter_people_status_pending',
          },
          {
            label: t('rejected'),
            value: 'rejected',
            id: 'filter_people_status_rejected',
          },
          {
            label: t('deactivated'),
            value: 'deactivated',
            id: 'filter_people_status_deactivated',
          },
        ],
      },
      {
        legendLabel: t('people-filter_label_members'),
        name: 'transportType',
        checkboxes: [
          {
            label: t('filters_drivers'),
            value: 'drivers',
            id: 'filter_people_journey_type_drivers',
            icon: 'car',
          },
          {
            label: t('filters_passengers'),
            value: 'passengers',
            id: 'filter_people_journey_type_passengers',
            icon: 'user-group',
          },
          {
            label: t('filters_walkers'),
            value: 'walkers',
            id: 'filter_people_journey_type_walkers',
            icon: 'person-walking',
          },
          {
            label: t('filters_cyclists'),
            value: 'cyclists',
            id: 'filter_people_journey_type_cyclists',
            icon: 'person-biking',
          },
        ],
      },
      {
        legendLabel: t('people-filter_label_roles'),
        name: 'role',
        checkboxes: [
          {
            label: t('filters_admins'),
            value: 'admins',
            id: 'filter_people_role_admin',
          },
          {
            label: t('filters_regular_users'),
            value: 'regular_users',
            id: 'filter_people_role_user',
          },
        ],
      },
    ],
    [t]
  );

  const translationKeys = useMemo(
    () =>
      ({
        id: t(settingsTranslations.id),
        first_name: t(settingsTranslations.firstName),
        last_name: t(settingsTranslations.lastName),
        joined_ts: t(settingsTranslations.joined),
        email: t(settingsTranslations.email),
        shiftLabel: t(settingsTranslations.shifts),
        name: t(settingsTranslations.name),
        status: t(settingsTranslations.status),
        lastactive_ts: t(settingsTranslations.lastActive),
        co2: t(settingsTranslations.co2),
        nox: t(settingsTranslations.nox),
        no_journeys: t(settingsTranslations.no_of_journeys),
        distance: t(settingsTranslations.distance),
      } as Record<Partial<keyof PeoplePageUser>, string>),
    [t]
  );

  const filterValues = useMemo(
    () => ({
      status: params.status?.split(',').filter(Boolean),
      transportType: params.transportType?.split(',').filter(Boolean),
      role: params.role?.split(',').filter(Boolean),
    }),
    [params]
  );

  const activeFiltersCount = useMemo(
    () =>
      (filterValues.status?.length || 0) +
      (filterValues.transportType?.length || 0) +
      (filterValues.role?.length || 0),
    [filterValues]
  );

  const actualSortColumn = useMemo(() => {
    const sortBy = activeColumnSort.sortBy as keyof PeoplePageUser;
    let actualSortBy;

    switch (sortBy) {
      case 'first_name':
        actualSortBy = 'name';
        break;
      case 'shift' as keyof PeoplePageUser:
        actualSortBy = 'shiftLabel';
        break;
      default:
        actualSortBy = sortBy;
    }

    return {
      ...activeColumnSort,
      sortBy: actualSortBy,
    } as SortOption<PeoplePageUser>;
  }, [activeColumnSort]);

  const resetAndFetch = useCallback(() => {
    revalidate();
    table?.deselectAll();
  }, [revalidate, table]);

  const handleOnChangeShift = useCallback(() => {
    revalidate();
    table.deselectAll();
  }, [revalidate, table]);

  const closeShiftModal = useCallback(() => {
    setShiftSettingsModal(false);
  }, []);

  const [actionRequest] = useCallbackAsync({
    showSpinner: false,
    deps: [communityId],
    callback: async (args: {
      userIds: string[];
      successMessage: string;
      apiRequest: (
        oid: number,
        ids: string[]
      ) => Promise<{ rc: 'ok' | 'error' }>;
    }) => {
      const result = await args.apiRequest(communityId!, args.userIds);
      if (result.rc === 'ok') {
        resetAndFetch();

        showSnackBar({
          actionButtonText: t('dismiss'),
          text: args.successMessage,
          variant: 'success',
        });
      } else {
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: `${t('rm_alert_title_travel_mode')} ${t(
            'something_went_wrong'
          )}`,
          variant: 'error',
        });
      }
    },
  });

  const communityPrompts = useCallback(
    (user?: PeoplePageUser, element?: HTMLElement) => {
      const userFullName = `${user?.first_name} ${user?.last_name}`.trim();

      return {
        [CommunityActions.APPROVE]: {
          title: t('people-approve_users_modal'),
          confirmButtonText: `${t('approve')} ${t('selected')}`,
          cancelButtonText: t('cancel'),
          triggerRef: editMenu?.trigger,
          onConfirm: () => {
            actionRequest({
              userIds: [checkedUsersIdsArray],
              apiRequest: apiPeople.approveSelectedPeople,
              successMessage: t('approved_to_join_community_message', {
                Community: community?.name,
              }),
            });
          },
        },
        [CommunityActions.REJECT]: {
          title: t('people-reject_users_modal'),
          confirmButtonText: `${t('reject')} ${t('selected')}`,
          confirmButtonVariant: 'delete-outline',
          cancelButtonText: t('cancel'),
          triggerRef: editMenu?.trigger,
          onConfirm: () => {
            actionRequest({
              userIds: [checkedUsersIdsArray],
              apiRequest: apiPeople.rejectSelectedPeople,
              successMessage: t('reject_to_join_community_message', {
                Community: community?.name,
              }),
            });
          },
        },
        [CommunityActions.DEACTIVATE]: {
          title: `${t('people-deactivate_users_modal')} ${
            someCheckedUsersAreAdmins
              ? t('people-deactivate_alert_some_of_the_people')
              : ''
          }`,
          confirmButtonText: `${t('deactivate')} ${t('selected')}`,
          cancelButtonText: t('cancel'),
          triggerRef: editMenu?.trigger,
          onConfirm: () => {
            actionRequest({
              userIds: [checkedUsersIdsArray],
              apiRequest: apiPeople.deactivateSelectedPeople,
              successMessage: t('people-deactivated_users', {
                Community: community?.name,
              }),
            });
          },
        },
        [CommunityActions.REACTIVATE]: {
          title: t('people-reactivate_users_modal'),
          confirmButtonText: `${t('global-button_reactivate')} ${t(
            'selected'
          )}`,
          cancelButtonText: t('cancel'),
          triggerRef: editMenu?.trigger,
          onConfirm: () => {
            actionRequest({
              userIds: [checkedUsersIdsArray],
              apiRequest: apiPeople.reactivateSelectedPeople,
              successMessage: t('approved_to_rejoin_community_message', {
                Community: community?.name,
              }),
            });
          },
        },
        [CommunityActions.APPROVE_ONE_USER]: {
          title: t('access_community_approval', {
            people: userFullName,
            numberOfPeople: undefined,
            communityName: community?.name,
          }),
          confirmButtonText: t('approve'),
          triggerRef: element,
          cancelButtonText: t('cancel'),
          onConfirm: async () => {
            actionRequest({
              userIds: [user?.id],
              apiRequest: apiPeople.approveSelectedPeople,
              successMessage: t('approved_to_join_community_message', {
                Community: community?.name,
              }),
            });
          },
        },
        [CommunityActions.REJECT_ONE_USER]: {
          type: 'delete',
          confirmButtonText: t('reject'),
          cancelButtonText: t('cancel'),
          confirmButtonVariant: 'delete-outline',
          title: t('access_community_rejection', {
            numberOfPeople: undefined,
            people: userFullName,
            communityName: community?.name,
          }),
          triggerRef: element,
          onConfirm: async () => {
            actionRequest({
              userIds: [user?.id],
              apiRequest: apiPeople.rejectSelectedPeople,
              successMessage: t('has_been_rejected_from_community', {
                personName: userFullName,
                communityName: community?.name,
              }),
            });
          },
        },
        [CommunityActions.DEACTIVATE_ONE_USER]: {
          title: t('deactivate_user_title', {
            userName: userFullName,
            communityName: community?.name,
          }),
          confirmButtonText: t('deactivate'),
          triggerRef: element,
          cancelButtonText: t('cancel'),
          onConfirm: async () => {
            actionRequest({
              userIds: [user?.id],
              apiRequest: apiPeople.deactivateSelectedPeople,
              successMessage: t('deactivate_user_message', {
                userName: userFullName,
                communityName: community?.name,
              }),
            });
          },
        },
        [CommunityActions.REACTIVATE_ONE_USER]: {
          title: t('reactivate_user_title', {
            userName: userFullName,
            communityName: community?.name,
          }),
          confirmButtonText: t('global-button_reactivate'),
          triggerRef: element,
          cancelButtonText: t('cancel'),
          onConfirm: async () => {
            actionRequest({
              userIds: [user?.id],
              apiRequest: apiPeople.reactivateSelectedPeople,
              successMessage: t('has_rejoined_community', {
                personName: userFullName,
                communityName: community?.name,
              }),
            });
          },
        },
      } as Record<number, PromptModalProps>;
    },
    [
      actionRequest,
      editMenu,
      community?.name,
      checkedUsersIdsArray,
      someCheckedUsersAreAdmins,
      t,
    ]
  );

  const tableActions = useMemo(
    () => (user: PeoplePageUser) =>
      (
        <Fragment>
          <Styled.ActionsContainer className="user-actions-wrapper">
            {user.authorised === 'N' && (
              <>
                <Button
                  id={`approve_pending_user_${user.id}`}
                  className="user-actions-wrapper__approve-user"
                  variant="ghost"
                  icon={<Icon name="check" />}
                  iconPosition="left"
                  onClick={async (e) => {
                    e.stopPropagation();

                    await prompts.standard(
                      communityPrompts(user, e.target as HTMLButtonElement)[
                        CommunityActions.APPROVE_ONE_USER
                      ]
                    );
                  }}
                >
                  {t('approve')}
                </Button>
                <Button
                  id={`reject_pending_user_${user.id}`}
                  className="user-actions-wrapper__reject-user"
                  variant="ghost"
                  icon={<Icon name="xmark" />}
                  iconPosition="left"
                  onClick={async (e) => {
                    e.stopPropagation();

                    await prompt(
                      communityPrompts(user, e.target as HTMLButtonElement)[
                        CommunityActions.REJECT_ONE_USER
                      ]
                    );
                  }}
                >
                  {t('reject')}
                </Button>
              </>
            )}
            {user.authorised === 'Y' && user.is_admin === 'N' && (
              <Button
                id={`deactivate_active_user_${user.id}`}
                className="user-actions-wrapper__deactivate-user"
                variant="delete-ghost"
                icon={<Icon name="ban" />}
                iconPosition="left"
                onClick={async (e) => {
                  e.stopPropagation();

                  await prompts.deactivate(
                    communityPrompts(user, e.target as HTMLButtonElement)[
                      CommunityActions.DEACTIVATE_ONE_USER
                    ]
                  );
                }}
              >
                {t('deactivate')}
              </Button>
            )}
            {user.authorised === 'D' && user.is_admin === 'N' && (
              <Button
                id={`reactivate_deactivated_user_${user.id}`}
                className="user-actions-wrapper__deactivate-user"
                variant="ghost"
                icon={<Icon name="rotate-right" />}
                iconPosition="left"
                onClick={async (e) => {
                  e.stopPropagation();

                  await prompts.standard(
                    communityPrompts(user, e.target as HTMLButtonElement)[
                      CommunityActions.REACTIVATE_ONE_USER
                    ]
                  );
                }}
              >
                {t('global-button_reactivate')}
              </Button>
            )}
            <Button
              id={`show_user_profile_${user.id}`}
              className="user-actions-wrapper__view-user-details"
              icon={<Icon name="chevron-right" />}
              iconPosition="right"
              variant="ghost"
              onClick={() => navigate(`${user.id}`)}
            >
              {t('view_details')}
            </Button>
          </Styled.ActionsContainer>
        </Fragment>
      ),
    [communityPrompts, navigate, prompt, prompts, t]
  );

  const headerMenuActions = useMemo(
    () =>
      [
        {
          children: t('Accept'),
          icon: <Icon name="check" />,
          disabled: !hasAtLeastOnePendingChecked,
          onClick: async () => {
            await prompts.standard(
              communityPrompts()[CommunityActions.APPROVE]
            );
          },
        },
        {
          children: t('reject'),
          icon: <Icon name="xmark" />,
          disabled: !hasAtLeastOnePendingChecked,
          onClick: async () => {
            await prompts.standard(communityPrompts()[CommunityActions.REJECT]);
          },
        },
        {
          children: t('deactivate'),
          icon: <Icon name="do-not-enter" />,
          disabled: !(
            hasAtLeastOneApprovedChecked && someApprovedCheckedUsersAreNotAdmins
          ),
          onClick: async () => {
            await prompts.deactivate(
              communityPrompts()[CommunityActions.DEACTIVATE]
            );
          },
        },
        {
          children: t('global-button_reactivate'),
          icon: <Icon name="rotate-right" />,
          disabled: !hasAtLeastOneDeactivatedChecked,
          onClick: async () => {
            await prompts.standard(
              communityPrompts()[CommunityActions.REACTIVATE]
            );
          },
        },
      ] as ButtonProps[],
    [
      prompts,
      communityPrompts,
      hasAtLeastOneApprovedChecked,
      hasAtLeastOneDeactivatedChecked,
      hasAtLeastOnePendingChecked,
      someApprovedCheckedUsersAreNotAdmins,
      t,
    ]
  );

  const headerActions = useMemo(
    () => (
      <div className="table-actions__btns">
        <Menu
          ref={editMenuRef}
          triggerProps={{
            variant: 'outline-invert',
            className: 'table-actions__edit-btn',
            icon: <Icon name="chevron-down" className="wcl-icon--rotate" />,
            iconPosition: 'right',
          }}
          triggerTitle={t('as_edit')}
          menuItems={headerMenuActions}
        />
        {!hasOnlyRejectedChecked && (
          <Button
            ref={shiftSettingsRef}
            variant="outline-invert"
            className="table-actions__shift-settigns-btn"
            onClick={() => setShiftSettingsModal(true)}
          >
            {t('shift_settings-button_shift_settings')}
          </Button>
        )}
      </div>
    ),
    [editMenuRef, t, headerMenuActions, hasOnlyRejectedChecked]
  );

  const [downloadPeopleCSV] = useCallbackAsync({
    callback: async () => {
      const { data, headers } = await apiPeople.exportPeople({
        organisationId: communityId!,
        search,
        unit,
      });

      saveAs(
        new Blob([data], {
          type:
            headers?.['content-type'] ||
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${t('people')}_${dayjs().format('DD-MMMM-YYYY')}`
      );
    },
    showSpinner: true,
  });

  const handleOnColumnSortClicked = useCallback(
    (sortOptions: SortOption<PeoplePageUser>) => {
      const { sortBy, sortDirection } = sortOptions;

      let actualSortBy = sortBy;

      switch (sortBy) {
        case 'name':
          actualSortBy = 'first_name';
          break;
        case 'shiftLabel':
          actualSortBy = 'shift' as keyof PeoplePageUser;
          break;
      }
      table?.deselectAll();
      setActiveColumnSort(actualSortBy, sortDirection);
    },
    [setActiveColumnSort, table]
  );

  const onSubmit = useCallback(
    async ({ status, transportType, role }: any) => {
      table.setCheckedRows([]);
      setFiltersModalOpen(false);
      setMultipleQueryParams({ status, transportType, role, page: 1 });
    },
    [setMultipleQueryParams, table]
  );

  const {
    isCancel,
    abortSignal: shiftsAbortSignal,
    cancelPreviousRequest: cancelAbortSignalRequest,
  } = useAbortController();

  // GET SHIFTS
  useEffect(() => {
    (async () => {
      if (!communityId || !userReady) return;

      cancelAbortSignalRequest();

      try {
        const { shifts } = await apiPredefinedShifts.getShifts(communityId, {
          signal: shiftsAbortSignal(),
        });
        setShifts(shifts);
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
        }
      }
    })();
  }, [
    communityId,
    userReady,
    isCancel,
    cancelAbortSignalRequest,
    shiftsAbortSignal,
  ]);

  useEffectSkipFirst(() => table?.deselectAll(true), [communityId]);

  return (
    <PageLayout className={classNames('kinto-page', 'people')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('mPeople')}
      </Heading>

      <div className="kinto-page__header">
        <CommunityElement />

        <Styled.HeaderBar className="people-header-bar">
          <div className="people-header-bar__bottom">
            <Button
              id="invite_people"
              ref={invitePeopleBtnRef}
              icon={<Icon name="plus" />}
              variant="outline"
              onClick={() => setInvitePeopleModalActive(true)}
            >
              {t('invite_people')}
            </Button>
            <InputField
              id="search_people_input"
              className="people-header-bar__bottom__input"
              value={search}
              onChange={onSearchChange}
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder={t('selgroup_search')}
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label={t('filters_clear_all')}
                    icon={<Icon name="xmark" />}
                    onClick={() => onSearchChange('')}
                  />
                ),
              })}
            />

            <button
              ref={filterBtnRef}
              className={classNames('kinto-filter-button', {
                'kinto-filter-button--filters-active': activeFiltersCount > 0,
              })}
              onClick={() => setFiltersModalOpen(true)}
            >
              <Icon name="filter" />
              <span>{t('filters')}</span>
              {activeFiltersCount > 0 && <span>{activeFiltersCount}</span>}
            </button>
            <Button
              onClick={downloadPeopleCSV}
              disabled={data.length === 0}
              variant="outline"
            >
              {t('webapp_exportCSV')}
            </Button>

            <ColumnSettingsButton className="people-header-bar__bottom__col-settings">
              {t('global-column_settings')}
            </ColumnSettingsButton>
          </div>
        </Styled.HeaderBar>
      </div>

      {!loading &&
      data.length === 0 &&
      params.role === '' &&
      params.status === '' &&
      params.transportType === '' ? (
        <section className="people__error">
          <div className="people__error__message">
            <Heading level="1" className="people__error__message__title">
              {errorMessage}
            </Heading>
            <>
              <div className="people__error__message__body">
                {t('invite_people_to_community')}
              </div>
              <Button
                id="invite_people_2"
                className="people__error__message__button"
                onClick={() => setInvitePeopleModalActive(true)}
                icon={<Icon name="plus" />}
              >
                {t('invite_people')}
              </Button>
            </>
          </div>
          <div className="people__error__illustration">
            <Image
              alt=""
              src="/assets/svg/empty_state_no_members.svg"
              className="people__error__illustration__image"
            />
          </div>
        </section>
      ) : (
        <Table<PeoplePageUser>
          cacheColumns
          tableId="people-table"
          className="people__table"
          ref={tableRef}
          tableData={data}
          expandable
          hasCheckboxes
          columnSettingsOpen={columnSettingsOpen}
          columnsModalLabels={columnTranslations}
          columnsBtnElement={columnBtnRef?.current!}
          loadingData={loading}
          sortIconAriaLabel={(property, orientation) =>
            t(
              orientation === 'desc'
                ? 'accessibility-button_sort_ascending'
                : 'accessibility-button_sort_descending',
              { property: property.toLowerCase() }
            )
          }
          perPageLabel={t('per_page')}
          chevronBtnAriaLabel={t('per_page')}
          closeColumnModalAriaLabel={t('accessibility-button_close_modal')}
          pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
          pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
          selectAllCheckboxAriaLabel={t('global-button_select_all')}
          noDataPlaceholder={
            !loading && (
              <NoData className="kinto-no-data">{t('no_members_found')}</NoData>
            )
          }
          rowActionLabel={t('view_details')}
          perPagePlaceholder={t('per_page')}
          headerActionLabel={t('shift_settings-members_selected')}
          excludeColumns={excludeColumns.current}
          initialSort={actualSortColumn}
          translationKeys={translationKeys}
          ariaLabelForHeaders={{
            co2: t('accessibility-CO2-saved'),
            nox: t('accessibility-NOx-saved'),
          }}
          selectAllThAriaLabel={t('global-button_select_all')}
          tableActions={tableActions}
          headerActions={() => headerActions}
          paginationData={{
            limit: count,
            totalPages,
            totalCount,
            currentPage,
          }}
          goToPageInputProps={{ placeholder: t('global-go_to_page') }}
          onColumnsModalClose={closeColumnSettings}
          onPageChange={(page) => setCurrentPage(page)}
          onColumnSortClicked={(data) => handleOnColumnSortClicked(data)}
          onLimitChange={(data: SelectOption) => {
            setCount(+data.value as ViewsPerPage);
            table.deselectAll();
          }}
        />
      )}

      {shiftSettingsModal && (
        <ShiftSettingsModal
          shifts={shifts}
          triggerRef={shiftSettingsRef.current as HTMLElement}
          assignedShifts={assignedUsersShifts}
          onClose={closeShiftModal}
          onChange={handleOnChangeShift}
        />
      )}

      {/* INVITE PEOPLE MODAL */}
      {invitePeopleModalActive && communityId && (
        <InvitePeopleModalViewWrapper
          organisationId={communityId}
          triggerRef={invitePeopleBtnRef.current!}
          onClose={() => {
            setInvitePeopleModalActive(false);
          }}
        />
      )}

      {filtersModalOpen && (
        <FiltersModal
          onClose={() => setFiltersModalOpen(false)}
          filters={filters}
          onSubmit={onSubmit}
          triggerRef={filterBtnRef.current!}
          initialData={filterValues}
        />
      )}
    </PageLayout>
  );
};

export default PeoplePage;
