import { Checkbox, Heading } from '@faxi/web-component-library';
import { t } from 'i18next';
import { memo, useContext, useMemo } from 'react';
import { InvitePeopleContext, UserContext } from 'store';
import ClipboardElement from '../../../../_molecules/ClipboardElement';
import InvitePeopleCode from '../../components/InvitePeopleCode';
import LinkPreview from '../../components/LinkPreview';
import * as Styled from './InvitePeople.styles';

const InvitePeople = ({ closeModal }: { closeModal: () => void }) => {
  const { community } = useContext(UserContext);

  const {
    magicLink,
    fastCode,
    loadingJoinCode,
    qrCodeURL,
    updateMagicLink,
    handleChangeJoinCode,
    downloadPDF,
    setQrCodeUrl,
  } = useContext(InvitePeopleContext);

  const isLinkCustomised = Boolean(magicLink?.deeplink);

  const link = magicLink?.deeplink ? magicLink.deeplink.alias : magicLink?.code;

  const mailToLink = useMemo(
    () =>
      `mailto:?subject=${t('send_email_link_subject')}&body=${t(
        'send_email_link_body',
        {
          community_link: encodeURIComponent(magicLink.code),
          community: community?.name,
        }
      )
        .replace(/\\r/gi, '%0D%0A%0D%0A')
        .replace(/&/gi, '%26')}`,
    [community, magicLink]
  );

  return (
    <Styled.InvitePeopleViewContainer className="invite-people-view">
      <ClipboardElement
        link={link}
        code={fastCode?.code}
        loading={loadingJoinCode}
        onCopy={closeModal}
        onUpdate={updateMagicLink}
      />

      {isLinkCustomised ? (
        <p className="invite-people-view__pre-approved-disclaimer">
          {t('invite_link-pre_approved_info')}
        </p>
      ) : (
        <Checkbox
          checked={!!magicLink.joincode}
          disabled={loadingJoinCode}
          className="invite-people-view__box"
          label={t('invite-people_button_preapprove_into_community')}
          onChange={handleChangeJoinCode}
        />
      )}

      <Heading level="2" className="invite-people-view__link-preview-title">
        {t('global-link_preview')}
      </Heading>

      <LinkPreview />

      <Heading level="2" className="invite-people-view__code-title">
        {t('invite-people_title_qr_code')}
      </Heading>

      <InvitePeopleCode
        link={link}
        qrCodeUrl={qrCodeURL}
        mailToLink={mailToLink}
        downloadPDF={downloadPDF}
        onGenerateCode={setQrCodeUrl}
      />
    </Styled.InvitePeopleViewContainer>
  );
};

export default memo(InvitePeople);
