import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  CheckboxGroup,
  Divider,
  Heading,
  Modal,
  ModalRef,
  RadioGroup,
  SelectOption,
  Tooltip,
  getColor,
  useCallbackRef,
  useQueryParams,
  useResize,
  useUtilities,
  useModalUtilities,
  KintoPrompt,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  FormState,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { responsiveSm } from '@faxi/web-css-utilities';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CalendarField,
  CheckboxGroupField,
  FormActions,
  Icon,
  InfoCard,
  InputField,
  RadioGroupField,
  SelectField,
  TextareaField,
} from 'components';

import { useCallbackAsync, useInfinitePagination } from 'hooks';
import {
  Depot,
  RewardType,
  DepotState,
  ApiUser,
  DepotTriggerName,
  DepotTags,
  GamificationDepotType,
  RewardVoucherTypeData,
} from 'models';
import { apiGamification, apiPeople } from 'modules';
import { CampaignContext, UserContext } from 'store';
import {
  CreateCampaignForm,
  mapCampaignFormDataForApi,
  retrieveNestedAction,
} from './utils';
import specific from 'validation/validators/specific';
import numberValidation from 'validation/validators/numberValidation';
import useConstants from '../../useConstants';
import CreateNewCampaignModal from './components/CreateNewCampaignModal';
import RewardDetailsModal from './components/RewardDetailsModal';

import * as Styled from './CreateNewCampaign.styles';

const getInstances = (rewards?: RewardType[], id?: string) => {
  if (!rewards?.length || !id) return [];
  return rewards?.find((r) => r.id === +id)?.instances || [];
};

const CONDITION_TRANSLATIONS = {
  'journey-finished-trigger': 'journey-pn_title_journey_finished',
  'journey-verified-trigger': 'Rideshare_confirmed_basic_notif',
} as Record<DepotTriggerName, string>;

const USERS_PER_PAGE = 20;
const MAX_REWARD_COUNT_ACCUMULATION_CAMPAIGN = 1000000;

const CreateNewCampaign: FC = () => {
  const { t } = useTranslation();
  const { prompts, showSnackBar } = useUtilities();

  const {
    userPreferences: { dateFormat },
  } = useContext(UserContext);

  const { rewards } = useContext(CampaignContext);

  const isPhablet = useResize(responsiveSm);

  const { organisationId, campaignId } = useParams() as {
    organisationId: string;
    campaignId: string;
  };

  const {
    params: { type: campaign_type },
  } = useQueryParams<{
    type: GamificationDepotType;
  }>();

  const modalRef = useRef<ModalRef>(null);
  const createBtnRef = useRef<HTMLButtonElement>(null);
  const directionBtnRef = useRef<HTMLButtonElement>(null);

  const { rewardWinners, statusOptions, directionOptions } = useConstants();

  const [tagConditionRadio, setTagConditionRadio] = useState<
    'allLocations' | 'custom'
  >('allLocations');

  const [selectDirectionModal, setSelectDirectionModal] = useState(false);

  //because select direction field is in modal and outside of form
  //we need to seperate state for that field, one to manipulate inside the modal
  const [selectDirections, setSelectDirections] = useState<SelectOption[]>([]);

  //state used to set error on button 'Select destination', direction/s are required
  //and bcs setting direction field is in modal we set error on button which open that modal
  const [conditionError, setConditionError] = useState(false);

  const [initialData, setInitialData] = useState<CreateCampaignForm>({
    name: '',
    condition: 'journey-finished-trigger',
    end_date: '',
    per_day: { label: t('campaigns-reward_repeat_per_day'), value: 'per_day' },
    start_date: '',
    rewards: [],
    participants: [],
    instructions: '',
    tags: directionOptions,
    exclude: [],
    state: 'pending',
    repeatable: '0',
    end_date_type: 'date',
    passengers: 1,
  });

  const {
    open: detailsModalOpen,
    triggerRef: viewDetailsBtnRef,
    closeModal: closeDetailsModal,
    ModalButton: ViewDetailsButton,
  } = useModalUtilities();

  const rewardDetails = useRef<RewardType>();

  const [campaignValues, setCampaignValues] = useState<Partial<Depot>>({
    state: initialData.state as DepotState,
  });

  const [form, formRef] = useCallbackRef<FormRef>();

  const [createCampaignModalState, setCreateCampaignModalState] =
    useState(false);

  const navigate = useNavigate();

  const formValues = useFormRefValues(
    form,
    'name',
    'rewards',
    'participants',
    'exclude',
    'condition',
    'start_date',
    'end_date',
    'instructions',
    'tags',
    'points',
    'repeatable',
    'end_date_type',
    'passengers'
  ) as CreateCampaignForm;

  const showPrompt = useRef<boolean>(true);

  const campaignType = useMemo(
    () => campaign_type || 'regular',
    [campaign_type]
  );

  const isDrivingCampaignOnly = useMemo(() => {
    return (
      JSON.stringify(formValues?.participants) === JSON.stringify(['driving'])
    );
  }, [formValues?.participants]);

  /*
    For Accumulation type campaigns we need to pull out rewards which have instances created
  **/
  const rewardsBasedOnCampaignType = useMemo(
    () =>
      campaignType === 'accumulation'
        ? rewards?.filter((reward) => reward.instances?.length === 0)
        : rewards,
    [campaignType, rewards]
  );

  const rewardsCheckboxFieldOptions = useMemo(
    () =>
      rewardsBasedOnCampaignType
        ?.sort(({ name: name1 }, { name: name2 }) =>
          name1.toLowerCase() > name2.toLowerCase() ? 1 : -1
        )
        .map(({ name, id }) => ({
          label: name,
          value: `${id}`,
        })) || [],
    [rewardsBasedOnCampaignType]
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('gamification-campaigns_label_campaign_name'),
          })
        ),
        validators.general.maxLength(
          30,
          t('validation_max_length', {
            value: '30',
          })
        ),
      ],
      condition: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('campaign_placeholder-status-condition'),
          })
        ),
      ],
      start_date: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('campaign-start_date'),
          })
        ),
        specific.dateAfter(
          t('start_date_before'),
          // validate only if end date exist and it is selected with radio button
          formValues?.end_date && formValues?.end_date_type === 'date'
            ? new Date(formValues?.end_date?.format('YYYY-MM-DD'))
            : undefined
        ),
      ],
      instructions: [
        validators.general.maxLength(
          500,
          t('validation_max_length', {
            value: '500',
          })
        ),
      ],
      participants: specific.requiredArray(t('field_is_required')),
      rewards: specific.requiredArray(
        t('validation-field_is_required', {
          fieldname: t('global-reward_s'),
        })
      ),
      points: [
        specific.positiveNumbersOnly(
          t('validation-field_positive_round_number_only', {
            fieldname: t('global-points').toLowerCase(),
          })
        ),
        validators.general.required(
          t('validation-fields_are_required', { fieldname: t('global-points') })
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t('global-points').toLowerCase(),
            number: 100000,
          }),
          100000
        ),
      ],
      reward_count: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('gamification-campaign_reward_number'),
          })
        ),
        specific.positiveNumbersOnly(
          t('validation-field_positive_round_number_only', {
            fieldname: t('gamification-campaign_reward_number').toLowerCase(),
          })
        ),
        numberValidation.maxValue(
          t('validation-field_validation_max_value', {
            fieldname: t('gamification-campaign_reward_number').toLowerCase(),
            number: 10000,
          }),
          10000
        ),
      ],
      passengers: [
        (value: string, allValues: any) => {
          if (
            JSON.stringify(allValues?.participants?.value) ===
            JSON.stringify(['driving'])
          ) {
            if (!value)
              return t('validation-field_is_required', {
                fieldname: t('global-pickup'),
              });

            return (
              specific.positiveNumbersOnly(
                t('validation-field_positive_round_number_only', {
                  fieldname: t('global-pickup').toLowerCase(),
                })
              )(value, allValues) ||
              numberValidation.maxValue(
                t('validation-field_validation_max_value', {
                  fieldname: t('global-pickup').toLowerCase(),
                  number: 100,
                }),
                100
              )(+value)
            );
          } else return undefined;
        },
      ],
    }),
    [formValues, t]
  );

  const disableSaveDraft = useMemo(
    () =>
      !formValues?.name ||
      formValues?.participants?.length === 0 ||
      (campaignType !== 'accumulation' && !formValues?.condition) ||
      !formValues?.start_date ||
      (campaignType === 'accumulation' && !formValues?.points) ||
      (formValues?.end_date_type === 'date' && !formValues?.end_date),
    [campaignType, formValues]
  );

  const findVouchersLimit = useCallback(
    (selectedRewards: SelectOption[]) => {
      // For vouchers we need to set limit as number_of_codes for every reward
      // first we create array of all selected voucher rewards
      const voucherRewards = rewards?.filter(
        (reward) =>
          selectedRewards.find(
            (rew: SelectOption) => +rew.value === reward.id
          ) && reward.type === 'voucher-reward'
      );

      // and then create Record with contain reward id as keys
      // and number_of_codes as value
      const addVouchersLimit = voucherRewards?.reduce((acc, voucherReward) => {
        return {
          ...acc,
          [`reward_count_${voucherReward.id}`]:
            (voucherReward?.data as RewardVoucherTypeData)?.number_of_codes ||
            1,
        };
      }, {});

      return addVouchersLimit;
    },
    [rewards]
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      //tags are outside of form in modal
      const expandedData = {
        ...data,
        tags:
          tagConditionRadio === 'allLocations'
            ? directionOptions
            : selectDirections,
      };

      //accumulation campaing have only one hardcoded trigger
      //there is not conditions field
      const triggers =
        campaignType === 'accumulation'
          ? ['transaction-added-trigger']
          : [expandedData.condition];

      const createCampaignData = mapCampaignFormDataForApi(
        {
          ...expandedData,
          ...findVouchersLimit(expandedData?.rewards),
          triggers,
        },
        +organisationId,
        campaignType
      );

      setCreateCampaignModalState(true);
      showPrompt.current = false;

      setCampaignValues((old) => ({
        ...old,
        ...(createCampaignData as any),
        excluded: data.exclude,
      }));
    },
    [
      tagConditionRadio,
      directionOptions,
      selectDirections,
      campaignType,
      findVouchersLimit,
      organisationId,
    ]
  );

  const [handleUpdateDraft] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    callback: async () => {
      //because we can not take rewards limits in useFormRefValues
      //we must get that values through fields directly
      let rewardsLimit = {};
      (formValues?.rewards as SelectOption[])?.forEach((reward) => {
        const value =
          form?.fields?.[
            `reward_count${
              campaignType === 'accumulation' ? '_accumulation' : ''
            }_${reward.value}`
          ]?.value;

        rewardsLimit = {
          ...rewardsLimit,
          [`reward_count${
            campaignType === 'accumulation' ? '_accumulation' : ''
          }_${reward.value}`]: value,
          [`reward_count_type_${reward.value}`]:
            form?.fields?.[`reward_count_type_${reward.value}`]?.value,
        };
      });

      //accumulation campaing have only one hardcoded trigger
      //there is not conditions field
      const triggers: DepotTriggerName[] =
        campaignType === 'accumulation'
          ? ['transaction-added-trigger']
          : [formValues.condition];

      const updatedData = mapCampaignFormDataForApi(
        {
          ...formValues,
          ...rewardsLimit,
          ...findVouchersLimit(formValues?.rewards || []),
          tags:
            tagConditionRadio === 'allLocations'
              ? directionOptions
              : selectDirections,
          triggers,
        },
        +organisationId,
        campaignType
      );

      const data = await apiGamification.updateDepot(campaignId, {
        ...(updatedData as any),
        state: 'draft',
      });

      if (data) {
        showSnackBar({ text: t('success'), variant: 'success' });
        navigate('..');
      }
    },
    deps: [formValues, organisationId, campaignId, campaignType],
  });

  const [handleDeleteDraft] = useCallbackAsync({
    showSpinner: false,
    callback: async (element: HTMLButtonElement) => {
      if (
        await prompts.delete({
          title: t('gamification-campaigns_modal_title_delete_draft'),
          confirmButtonText: t('Discovery_map_delete'),
          cancelButtonText: t('cancel'),
          triggerRef: element,
        })
      ) {
        const { data } = await apiGamification.deleteDepot(campaignId);
        if (data.status === 'Success') {
          navigate('..');

          showSnackBar({
            actionButtonText: t('dismiss'),
            text: t('gamification-campaigns_notification_draft_deleted'),
            variant: 'success',
          });
        }
      }
    },
    deps: [campaignId, navigate, organisationId, t],
  });

  const [handleSaveDraft] = useCallbackAsync({
    showSpinner: false,
    callback: async (element: HTMLButtonElement) => {
      //because we can not take rewards limits in useFormRefValues
      //we must get that values through fields directly
      let rewardsLimit = {};

      (formValues?.rewards as SelectOption[])?.forEach((reward) => {
        const value =
          form?.fields?.[
            `reward_count${
              campaignType === 'accumulation' ? '_accumulation' : ''
            }_${reward.value}`
          ]?.value;

        rewardsLimit = {
          ...rewardsLimit,
          [`reward_count${
            campaignType === 'accumulation' ? '_accumulation' : ''
          }_${reward.value}`]: value,
          [`reward_count_type_${reward.value}`]:
            form?.fields?.[`reward_count_type_${reward.value}`]?.value,
        };
      });

      //accumulation campaing have only one hardcoded trigger
      //there is not conditions field
      const triggers: DepotTriggerName[] =
        campaignType === 'accumulation'
          ? ['transaction-added-trigger']
          : [formValues.condition];

      const values = {
        ...formValues,
        end_date:
          formValues?.end_date_type === 'never' ? '' : formValues?.end_date,
        tags:
          tagConditionRadio === 'allLocations'
            ? directionOptions
            : selectDirections,
        triggers,
        ...rewardsLimit,
        ...findVouchersLimit(formValues?.rewards || []),
      };

      const formData = mapCampaignFormDataForApi(
        values,
        +organisationId,
        campaignType
      );

      if (
        await prompts.standard({
          title: t('gamification-campaigns_create_save_as_a_draft'),
          confirmButtonText: t('Save'),
          cancelButtonText: t('cancel'),
          triggerRef: element,
        })
      ) {
        const { data } = await apiGamification.createDepot({
          ...(formData as any),
          state: 'draft',
        });

        if (data) {
          navigate('..');

          showSnackBar({
            actionButtonText: t('dismiss'),
            text: `${t('global-draft')} ${t('groupreg_js_created')}`,
            variant: 'success',
          });
        }
      }
    },
    deps: [formValues, campaignType, organisationId, navigate, t],
  });

  const [getCampaign] = useCallbackAsync({
    callback: async () => {
      if (campaignId) {
        const {
          data: {
            data: {
              start_date,
              end_date,
              data,
              state,
              triggers,
              actions,
              instructions,
              name,
              excluded,
            },
          },
        } = await apiGamification.getDepotById(campaignId);

        let rewardsLimit = {};
        (data?.rewards as RewardType[])?.forEach((reward) => {
          rewardsLimit = {
            ...rewardsLimit,
            [`reward_count${
              campaignType === 'accumulation' ? '_accumulation' : ''
            }_${reward.id}`]: reward.limit,
          };
          rewardsLimit = {
            ...rewardsLimit,
            [`reward_count_type_${reward.id}`]: !reward.limit
              ? 'unlimitedRewards'
              : 'limitedRewards',
          };
        });

        const tagsAction = retrieveNestedAction('if-tag', actions[0]) as {
          name: 'if-tag';
          data: { tags: DepotTags[] };
        } | null;

        const tagsActionsTags = tagsAction?.data.tags;

        const tags =
          Number(tagsActionsTags?.length) > 0
            ? directionOptions.filter(({ value }) =>
                tagsActionsTags!.includes(value as DepotTags)
              )
            : [];

        //direction is outside of form so it is not part of initialData we must set state separately
        if (
          tagsAction?.data.tags?.length === directionOptions.length ||
          !tagsAction?.data.tags
        ) {
          setTagConditionRadio('allLocations');
        } else {
          setTagConditionRadio('custom');
          setSelectDirections(tags);
        }

        const participantAction = retrieveNestedAction(
          'if-is-participant',
          actions[0]
        ) as {
          name: 'if-is-participant';
          data: { participants: string[] };
        } | null;

        const participants = participantAction
          ? participantAction.data.participants
          : [];

        const accumulationCampaignDetails = retrieveNestedAction(
          'if-accumulated',
          actions?.[0]
        );

        const passengers = retrieveNestedAction(
          'if-participants-number',
          actions?.[0]
        );

        setInitialData({
          name,
          //start_date and end_date will be preffiled only on editing draft and if start_date of that draft is after today,
          //when we reuse campaign we want to clean dates
          start_date:
            state === 'draft' &&
            dayjs(start_date).isAfter(dayjs().subtract(1, 'day'))
              ? dayjs(start_date).hour(0)
              : undefined,
          end_date:
            end_date &&
            state === 'draft' &&
            dayjs(start_date).isAfter(dayjs().subtract(1, 'day'))
              ? dayjs(end_date).hour(0)
              : undefined,
          condition: triggers?.[0]?.name,
          rewards: (data?.rewards as RewardType[])
            .filter(({ name }: RewardType) => name)
            ?.map(({ name, id }: RewardType) => {
              return {
                label: `${name}`,
                value: `${id}`,
              };
            }),
          participants,
          state,
          instructions,
          tags,
          per_day: {
            label: t('campaigns-reward_repeat_per_day'),
            value: 'per_day',
          },
          exclude: (
            excluded as unknown as (Pick<
              ApiUser,
              'first_name' | 'last_name'
            > & { id_user: string })[]
          )?.map(
            ({ id_user, first_name, last_name }) =>
              ({
                id: id_user,
                label: [first_name, last_name].join(' ').trim(),
                value: id_user,
              } as unknown as SelectOption<string>)
          ),
          points: accumulationCampaignDetails?.data?.threshold,
          repeatable: accumulationCampaignDetails?.data.repeatable ? '1' : '0',
          end_date_type: end_date ? 'date' : 'never',
          passengers: passengers?.data?.threshold || 1,
          ...rewardsLimit,
        });

        setCampaignValues((old) => ({ ...old, state }));
      }
    },
    deps: [campaignId],
  });

  const { loading, items, handleContainerScroll, onSearchChange } =
    useInfinitePagination<ApiUser, SelectOption>({
      perPage: USERS_PER_PAGE,
      // condition: userReady && !!user,
      // deps: [communityId, userId],
      spinnerParent: '.kinto-page',
      apiRequest: async (currentPage: string, search: string) => {
        const { users, total_count } = await apiPeople.getPeople({
          count: USERS_PER_PAGE,
          offset: +currentPage,
          organisationId: +organisationId,
          search,
          status: ['accepted'],
        });

        return {
          data: users,
          total: total_count,
        };
      },
      includeQueryParam: false,
      mappingFunction: async (items: ApiUser[]) =>
        items.map(({ id, first_name, last_name }) => ({
          id: `${id}`,
          label:
            first_name || last_name
              ? [first_name, last_name].join(' ').trim()
              : '-',
          value: `${id}`,
        })),
    });

  useEffect(() => {
    getCampaign();
  }, [getCampaign]);

  return (
    <Styled.Container className="create-new-campaign">
      <div className="create-new-campaign__header">
        <Heading level="1" color={getColor('--PRIMARY_1_1')}>
          {t('gamification-campaigns_title_create_a_campaign')}
          {/* TODO: uncoment this when accumulation is ready */}
          {/* <span className="create-new-campaign__campaign-type">
            {` (${t(
              `${
                campaignType === 'accumulation'
                  ? 'rewards-title_accumulation_campaign'
                  : 'rewards-title_regular_campaign'
              }`
            )})`}
          </span> */}
        </Heading>
        <Button
          variant="ghost"
          onClick={(e) => {
            window.viewChangesBtnRef = e.target as HTMLElement;
            navigate('..');
          }}
          icon={<Icon name="xmark" />}
          iconPosition="right"
        >
          {t('PendingNotification_close')}
        </Button>
      </div>
      <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
        <FormField
          className="create-new-campaign__name"
          name="name"
          type="text"
          component={InputField}
          autoComplete="on"
          placeholder={t('gamification-campaigns_label_campaign_name')}
          validate={validations.name}
          required
          requiredLabel={t('global-input_field_required_label')}
        />

        <div className="create-new-campaign__section create-new-campaign__section__rewards">
          <div>
            <fieldset id="rewards_fieldset">
              <legend>{t('account-rewards')}</legend>

              <div className="create-new-campaign__rewards">
                {rewards &&
                  (rewardsCheckboxFieldOptions?.length !== 0 ? (
                    <Fragment>
                      <FormField
                        name="rewards"
                        multiple
                        autoComplete="off"
                        component={SelectField}
                        options={rewardsCheckboxFieldOptions}
                        placeholder={t('global-reward_s')}
                        searchable
                        searchableNoResultsTitle={t('search_no_results_found')}
                        hasClearAction
                        clearTitle={t('global_clear')}
                        onClear={() => {
                          form.updateValueField('rewards', []);
                        }}
                        validate={validations.rewards}
                        required
                        requiredLabel={t('global-input_field_required_label')}
                        onChange={(value) => {
                          const options = value as SelectOption[];

                          if (campaignType === 'accumulation') {
                            setInitialData((old) => ({
                              ...old,
                              [`reward_count_type_${
                                options[options.length - 1]?.value
                              }`]: 'limitedRewards',
                            }));
                          } else {
                            //new added reward should have initial value 1
                            setInitialData((old) => ({
                              ...old,
                              [`reward_count_${
                                options[options.length - 1]?.value
                              }`]:
                                getInstances(
                                  rewards,
                                  options[options.length - 1]?.value
                                ).reduce(
                                  (current, instance) =>
                                    (current += instance.quantity),
                                  0
                                ) || 1,
                            }));

                            //when we remove reward we need to clean limit for that reward

                            const optionValues = options.map(
                              ({ value: optionValue }: SelectOption) =>
                                optionValue
                            );

                            //found differeces between initialData and current value
                            const filteredArray = initialData.rewards
                              ?.map(({ value }) => value)
                              .filter((value) => !optionValues.includes(value));

                            //for all that differ set initial value to 0
                            const newLimit = filteredArray?.reduce(
                              (o, id) =>
                                Object.assign(o, { [`reward_count_${id}`]: 0 }),
                              {}
                            );

                            setInitialData((old) => ({ ...old, ...newLimit }));
                          }
                        }}
                      />
                    </Fragment>
                  ) : (
                    <div className="create-new-campaign__empty-rewards">
                      <div className="create-new-campaign__empty-rewards__label">
                        <Icon name="gift" color={getColor('--SHADE_1_4')} />
                        <div>
                          {t(
                            'gamification-campaigns_create_empty_state_no_available_rewards'
                          )}
                        </div>
                      </div>
                      <InfoCard
                        orientation="vertical"
                        text={t(
                          'gamification_campaigns_alert_you_need_to_add_at_least_one_reward'
                        )}
                        link={{
                          label: t('global-gamification_go_to_rewards_page'),
                          linkProps: {
                            to: `/community/${organisationId}/admin/gamification/rewards`,
                          },
                        }}
                      />
                    </div>
                  ))}
              </div>
            </fieldset>

            <fieldset className="create-new-campaign__section__fieldset">
              <legend>{t('campaign-who_can_win')}</legend>

              <FormField
                component={CheckboxGroupField}
                checkboxes={rewardWinners}
                name="participants"
                variant="chip"
                validate={validations.participants}
                required
                requiredLabel={t('global-input_field_required_label')}
              />
            </fieldset>
          </div>

          {formValues?.rewards?.map(({ value, label }) =>
            campaignType === 'regular' ? (
              <div
                key={value}
                className="create-new-campaign__rewards__add-reward"
              >
                <div className="create-new-campaign__rewards__add-reward__reward-name">
                  {label}
                </div>

                {rewards?.find((r) => r.id === +value)?.type ===
                'voucher-reward' ? (
                  <ViewDetailsButton
                    variant="outline"
                    className="create-new-campaign__rewards__add-reward__view-details--voucher"
                    onClick={() => {
                      const reward = rewards?.find((r) => r.id === +value);
                      rewardDetails.current = reward;
                    }}
                  >
                    {t('view_details')}
                  </ViewDetailsButton>
                ) : (
                  <>
                    <FormField
                      component={InputField}
                      name={`reward_count_${value}`}
                      inputMode="numeric"
                      className="create-new-campaign__rewards__add-reward__reward_count"
                      required
                      placeholder={t('global-reward_s')}
                      requiredLabel={t('global-input_field_required_label')}
                      disabled={!!getInstances(rewards, value)?.length}
                      validate={validations.reward_count}
                    />
                    <FormField
                      component={SelectField}
                      name="per_day"
                      autoComplete="off"
                      options={[
                        {
                          label: t('campaigns-reward_repeat_per_day'),
                          value: 'per_day',
                        },
                      ]}
                      disabled
                      required
                      requiredLabel={t('global-input_field_required_label')}
                    />

                    {!!getInstances(rewards, value)?.length && (
                      <ViewDetailsButton
                        variant="outline"
                        className="create-new-campaign__rewards__add-reward__view-details"
                        onClick={() => {
                          const reward = rewards?.find((r) => r.id === +value);
                          rewardDetails.current = reward;
                        }}
                      >
                        {t('view_details')}
                      </ViewDetailsButton>
                    )}
                  </>
                )}

                <Button
                  className="delete-reward"
                  variant="ghost"
                  icon={<Icon name="xmark" />}
                  aria-label={t('accessibility-button-delete-reward', {
                    rewardName: label,
                  })}
                  onClick={() => {
                    form.updateValueField(`reward_count_${value}`, 0);
                    form.updateValueField(
                      'rewards',
                      formValues?.rewards?.filter((rew) => rew.value !== value)
                    );

                    //when we remove reward we need to clean limit for that reward
                    setInitialData((old) => ({
                      ...old,
                      [`reward_count_${value}`]: 0,
                    }));
                  }}
                />
              </div>
            ) : (
              <div
                key={value}
                className="create-new-campaign__rewards__reward-number"
              >
                <div className="create-new-campaign__rewards__reward-number__title">
                  <div className="reward-name">{label}</div>
                  <Button
                    className="delete-reward"
                    variant="ghost"
                    icon={<Icon name="xmark" />}
                    aria-label={t('accessibility-button-delete-reward', {
                      rewardName: label,
                    })}
                    onClick={() => {
                      form.updateValueField(`reward_count_${value}`, 0);
                      form.updateValueField(
                        'rewards',
                        formValues?.rewards?.filter(
                          (rew) => rew.value !== value
                        )
                      );

                      //when we remove reward we need to clean limit for that reward
                      setInitialData((old) => ({
                        ...old,
                        [`reward_count_${value}`]: 0,
                      }));
                    }}
                  />
                </div>
                <FormField
                  component={RadioGroupField}
                  name={`reward_count_type_${value}`}
                  className="create-new-campaign__rewards__reward-number__radio"
                  orientation={isPhablet ? 'row' : 'column'}
                  options={[
                    {
                      value: 'limitedRewards',
                      label: (
                        <div className="create-new-campaign__rewards__reward-number__count">
                          <FormField
                            name={`reward_count_accumulation_${value}`}
                            component={InputField}
                            className="create-new-campaign__rewards__reward-number__count__input"
                            inputMode="numeric"
                            required
                            disabled={
                              form?.fields?.[`reward_count_type_${value}`]
                                ?.value === 'unlimitedRewards'
                            }
                            validate={(valueProp: string, fields: FormState) =>
                              specific.rewardCountAccumulationCampaigns(
                                valueProp,
                                fields,
                                value,
                                MAX_REWARD_COUNT_ACCUMULATION_CAMPAIGN
                              )
                            }
                          />
                          <span>{t('global-reward_s')}</span>
                        </div>
                      ),
                    },
                    {
                      value: 'unlimitedRewards',
                      label: t('reward_campaign-unlimited_number_of_rewards'),
                    },
                  ]}
                  onChange={(valueProp) => {
                    if (valueProp === 'unlimitedRewards') {
                      //when there is error in reward count input and change radio value
                      //we want to clear that unvalid value
                      if (
                        !form?.fields?.[`reward_count_accumulation_${value}`]
                          ?.valid
                      ) {
                        form?.updateValueField(
                          `reward_count_accumulation_${value}`,
                          0
                        );
                      }

                      form?.clearFieldValidationProps(
                        `reward_count_accumulation_${value}`
                      );
                    }
                    form?.updateValueField(
                      `reward_count_type_${value}`,
                      valueProp
                    );
                  }}
                />
              </div>
            )
          )}
        </div>

        {campaignType === 'accumulation' && (
          <div className="create-new-campaign__section">
            <fieldset>
              <legend>{t('global-accumulation')}</legend>

              <FormField
                name="points"
                component={InputField}
                className="create-new-campaign__points-field"
                inputMode="numeric"
                required
                placeholder={t('global-points')}
                requiredLabel={t('global-input_field_required_label')}
                validate={validations.points}
              />

              <FormField
                component={RadioGroupField}
                name="repeatable"
                className="create-new-campaign__reward-repeatable"
                orientation="column"
                label={t('reward_campaign-title_user_can_earn_reward')}
                options={[
                  {
                    label: t('global-once'),
                    value: '0',
                  },
                  {
                    label: t('reward_campaign-earn_reward_unlimited_times'),
                    value: '1',
                  },
                ]}
              />
            </fieldset>
          </div>
        )}

        <div className="create-new-campaign__section">
          <fieldset className="create-new-campaign__section__fieldset">
            <legend>{t('global-participants')}</legend>

            <div className="create-new-campaign__section__fieldset__field-container">
              <FormField
                name="exclude"
                multiple
                component={SelectField}
                options={items}
                autoComplete="off"
                placeholder={t('campaigns-exclude_users')}
                searchable
                searchableNoResultsTitle={t('search_no_results_found')}
                hasClearAction
                clearTitle={t('shift_settings-button_clear_all')}
                async
                loading={loading}
                onContainerScroll={handleContainerScroll}
                onSearchChange={onSearchChange}
                onClear={() => form?.updateValueField('exclude', [])}
              />

              <Tooltip
                content={t('campaigns-tooltip_exclude_users')}
                placement="top"
              >
                <span
                  tabIndex={0}
                  role="tooltip"
                  className="create-new-campaign__tooltip"
                  aria-label={t('campaigns-tooltip_exclude_users')}
                >
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                </span>
              </Tooltip>
            </div>
          </fieldset>
        </div>

        {/* For accumulation type campaigns we dont show Conditions sections */}
        {campaignType !== 'accumulation' && (
          <div className="create-new-campaign__section">
            <fieldset className="create-new-campaign__section__fieldset">
              <legend>
                <span>{t('global-conditions')}</span>
                <Tooltip
                  content={t(
                    'gamification-campaigns_tooltip_condition_people_need_to_meet'
                  )}
                  placement="top"
                >
                  <span
                    tabIndex={0}
                    role="tooltip"
                    className="create-new-campaign__tooltip"
                    aria-label={t(
                      'gamification-campaigns_tooltip_condition_people_need_to_meet'
                    )}
                  >
                    <Icon
                      name="circle-info"
                      color={getColor('--PRIMARY_1_1')}
                    />
                  </span>
                </Tooltip>
              </legend>

              <div
                className={classNames(
                  'create-new-campaign__section__fieldset__field-container',
                  'create-new-campaign__section__fieldset__field-container--column'
                )}
              >
                <FormField
                  name="condition"
                  renderAsPortal
                  component={SelectField}
                  options={statusOptions}
                  autoComplete="off"
                  placeholder={t('campaign_placeholder-status-condition')}
                  className="create-new-campaign__condition"
                  validate={validations.condition}
                  required
                  requiredLabel={t('global-input_field_required_label')}
                />

                {formValues?.condition && (
                  <div className="create-new-campaign__condition-tag">
                    <div>
                      <span className="create-new-campaign__condition-tag__header">
                        {
                          statusOptions.find(
                            (statusOption) =>
                              statusOption.value === formValues?.condition
                          )?.label
                        }
                      </span>

                      <div className="create-new-campaign__condition-tag__radio-btns">
                        <RadioGroup
                          options={[
                            {
                              label: t('campaign-condition_to_all_locations'),
                              value: 'allLocations',
                            },
                            {
                              label: t('global-custom'),
                              value: 'custom',
                            },
                          ]}
                          orientation="row"
                          value={tagConditionRadio}
                          onChange={(value) => {
                            setTagConditionRadio(
                              value as 'allLocations' | 'custom'
                            );
                          }}
                        />
                        {/* todo:add new component for this */}
                        {tagConditionRadio === 'custom' &&
                          (selectDirections?.length !== 0 ? (
                            <div className="create-new-campaign__condition-tag__select-destination">
                              <span className="create-new-campaign__condition-tag__required-lbl">
                                *{t('global-input_field_required_label')}
                              </span>
                              <Button
                                variant="ghost"
                                onClick={() => setSelectDirectionModal(true)}
                                className="create-new-campaign__condition-tag__select-destination__btn"
                              >
                                <Icon name="pen" />
                                {selectDirections?.map((selectDirection) => (
                                  <span key={selectDirection.value}>
                                    {
                                      directionOptions.find(
                                        (directionOption) =>
                                          directionOption.value ===
                                          selectDirection.value
                                      )?.label
                                    }
                                  </span>
                                ))}
                              </Button>
                            </div>
                          ) : (
                            <div className="create-new-campaign__condition-tag__select-destination">
                              <span className="create-new-campaign__condition-tag__required-lbl">
                                *{t('global-input_field_required_label')}
                              </span>
                              <Button
                                ref={directionBtnRef}
                                variant="ghost"
                                onClick={() => setSelectDirectionModal(true)}
                                className={classNames([
                                  'create-new-campaign__condition-tag__select-destination__btn',
                                  {
                                    'create-new-campaign__condition-tag__select-destination__btn--error':
                                      conditionError,
                                  },
                                ])}
                              >
                                <Icon name="plus" />
                                <span>{t('global-select')}</span>
                              </Button>
                              {conditionError && (
                                <span className="create-new-campaign__condition-tag__error-lbl">
                                  {t('select_option')}
                                </span>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    <Button
                      variant="ghost"
                      className="delete-condition"
                      aria-label={t('accessibility-button-delete-condition', {
                        conditionName: t(
                          CONDITION_TRANSLATIONS[formValues.condition]
                        ),
                      })}
                      icon={<Icon name="xmark" />}
                      onClick={() => {
                        // setSelectDirections([]);
                        setTagConditionRadio('allLocations');
                        form.updateValueField('condition', undefined);
                      }}
                    />
                  </div>
                )}

                <div className="create-new-campaign__passenger-number">
                  <div
                    className={classNames([
                      'create-new-campaign__passenger-number__input',
                      {
                        'create-new-campaign__passenger-number__input--disabled':
                          !isDrivingCampaignOnly,
                      },
                    ])}
                  >
                    <FormField
                      component={InputField}
                      name="passengers"
                      inputMode="numeric"
                      placeholder={t('global-pickup')}
                      disabled={!isDrivingCampaignOnly}
                      validate={validations.passengers}
                    />

                    <div
                      className={classNames([
                        'create-new-campaign__passenger-number__input__label',
                        {
                          'create-new-campaign__passenger-number__input__label--disabled':
                            !isDrivingCampaignOnly,
                        },
                      ])}
                    >
                      {t('global-passenger_s')}
                    </div>
                  </div>
                  {!isDrivingCampaignOnly && (
                    <div className="create-new-campaign__passenger-number__error">
                      {t('campaign-validation_passengers_pickup')}
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
          </div>
        )}

        {selectDirectionModal && (
          <Modal
            ref={modalRef}
            triggerRef={directionBtnRef.current as HTMLElement}
            ariaCloseModal={t('accessibility-button_close_modal', {
              name: t('global-select_location'),
            })}
            title={t('global-select_location')}
            footer={
              <FormActions className="kinto-modal__actions">
                <Button
                  onClick={() => {
                    modalRef.current?.close();
                    // setSelectDirections(selectDirections);
                    if (selectDirections?.length === 0) {
                      setConditionError(true);
                    }
                  }}
                  disabled={selectDirections.length === 0}
                >
                  {t('global-select')}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => modalRef.current?.close()}
                >
                  {t('Cancel')}
                </Button>
              </FormActions>
            }
            onClose={() => {
              setSelectDirectionModal(false);
              if (selectDirections?.length === 0) {
                setConditionError(true);
              }
            }}
          >
            <CheckboxGroup
              checkboxes={directionOptions}
              variant="chip"
              name="tags"
              value={selectDirections.map((s) => s.value)}
              onChange={(values) => {
                setSelectDirections(
                  directionOptions.filter(({ value }) => values.includes(value))
                );
              }}
            />
          </Modal>
        )}

        <div className="create-new-campaign__section">
          <fieldset className="create-new-campaign__section__fieldset">
            <legend>{t('global-duration')}</legend>

            <div
              className={classNames(
                'create-new-campaign__section__fieldset__field-container',
                'create-new-campaign__section__fieldset__field-container--column'
              )}
            >
              <div className="create-new-campaign__start-date-options">
                <p className="start-date-title">{t('campaign-start_date')}</p>
                <FormField
                  name="start_date"
                  component={CalendarField}
                  renderAsPortal
                  placeholder={t('campaign-start_date')}
                  leftLimitDate={dayjs().add(1, 'hour')}
                  rightLimitDate={
                    formValues?.end_date && formValues?.end_date_type === 'date'
                      ? formValues?.end_date
                      : dayjs().add(20, 'years')
                  }
                  validate={validations.start_date}
                  dateFormat={dateFormat}
                  required
                  requiredLabel={t('global-input_field_required_label')}
                />
              </div>

              <Divider />

              <div className="create-new-campaign__end-date-options">
                <FormField
                  component={RadioGroupField}
                  name="end_date_type"
                  orientation={isPhablet ? 'row' : 'column'}
                  options={[
                    {
                      value: 'date',
                      label: t('campaign-end_date'),
                      ariaLabel: t('campaign-end_date'),
                    },
                    {
                      label: (
                        <span>
                          {t('global-ongoing')}
                          <Tooltip
                            placement="top"
                            content={t(
                              'gamification-campaigns_end_date_never_info'
                            )}
                          >
                            <span
                              role="tooltip"
                              tabIndex={
                                formValues?.end_date_type === 'never' ? 0 : -1
                              }
                              className="create-new-campaign__tooltip"
                              aria-label={t(
                                'gamification-campaigns_end_date_never_info'
                              )}
                            >
                              <Icon
                                name="circle-info"
                                color={getColor('--PRIMARY_1_1')}
                              />
                            </span>
                          </Tooltip>
                        </span>
                      ),
                      value: 'never',
                    },
                  ]}
                  onChange={(value) => {
                    if (value === 'never') {
                      form?.clearFieldValidationProps('end_date');
                    }
                    form?.updateValueField('end_date_type', value);
                  }}
                />

                <FormField
                  name="end_date"
                  component={CalendarField}
                  renderAsPortal
                  placeholder={t('campaign-end_date')}
                  leftLimitDate={formValues?.start_date || dayjs()}
                  rightLimitDate={dayjs().add(20, 'years')}
                  disabled={formValues?.end_date_type === 'never'}
                  validate={specific.endDateCampaignValidation}
                  dateFormat={dateFormat}
                  required
                  requiredLabel={t('global-input_field_required_label')}
                />
              </div>
            </div>
          </fieldset>
        </div>

        <div className="create-new-campaign__section">
          <fieldset className="create-new-campaign__section__fieldset">
            <legend>{t('campaign-how_to_win')}</legend>

            <FormField
              name="instructions"
              component={TextareaField}
              noresize
              placeholder={t('campaign-instructions')}
              validate={validations.instructions}
            />
          </fieldset>
        </div>

        <div className="create-new-campaign__actions">
          <div className="create-new-campaign__actions__regular">
            <Button
              ref={createBtnRef}
              type="submit"
              disabled={
                !form?.syncFormValid ||
                rewards?.length === 0 ||
                //when draft is created disable button if there is not rewards selected
                (initialData.state === 'draft' &&
                  formValues.rewards?.length === 0)
              }
            >
              {t('global_button_create')}
            </Button>
            <Button
              onClick={(e) => {
                window.viewChangesBtnRef = e.target as HTMLElement;
                navigate('..');
              }}
              type="button"
              variant="ghost"
            >
              {t('cancel')}
            </Button>
          </div>

          <div className="create-new-campaign__actions__draft">
            {initialData && initialData?.state === 'draft' ? (
              <Fragment>
                <Button
                  variant="outline"
                  onClick={() => {
                    showPrompt.current = false;
                    handleUpdateDraft();
                  }}
                >
                  {t('global-update_draft')}
                </Button>

                <Button
                  icon={<Icon name="trash-can" />}
                  variant="delete-ghost"
                  iconPosition="right"
                  type="button"
                  onClick={(e) => {
                    showPrompt.current = false;
                    handleDeleteDraft(e.target);
                  }}
                >
                  {t('global-delete_draft')}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  variant="outline"
                  onClick={(e) => {
                    showPrompt.current = false;
                    handleSaveDraft(e.target);
                  }}
                  disabled={!form?.syncFormValid || disableSaveDraft}
                >
                  {t('gamification-campaigns_create_save_as_a_draft')}
                </Button>
                <Tooltip
                  placement="top"
                  content={t(
                    'gamification-campaigns_tooltip_draft_wont_be_activated'
                  )}
                >
                  <span
                    tabIndex={0}
                    role="tooltip"
                    className="create-new-campaign__tooltip"
                    aria-label={t(
                      'gamification-campaigns_tooltip_draft_wont_be_activated'
                    )}
                  >
                    <Icon
                      name="circle-info"
                      color={getColor('--PRIMARY_1_1')}
                    />
                  </span>
                </Tooltip>
              </Fragment>
            )}
          </div>
        </div>
      </Form>

      {createCampaignModalState && (
        <CreateNewCampaignModal
          campaignValues={campaignValues}
          triggerRef={createBtnRef.current as HTMLElement}
          onClose={() => {
            setCreateCampaignModalState(false);
            createBtnRef.current?.focus();
          }}
        />
      )}

      {detailsModalOpen && rewardDetails.current && (
        <RewardDetailsModal
          reward={rewardDetails.current}
          triggerRef={viewDetailsBtnRef.current as HTMLButtonElement}
          onClose={closeDetailsModal}
        />
      )}

      <KintoPrompt
        when={form?.isFormChanged() && showPrompt.current}
        config={{
          cancelButtonText: t('onboarding_continue'),
          confirmButtonText: t('super_admin-button_view_changes'),
          title: t('super_admin-you_have_usaved_changes'),
        }}
      />
    </Styled.Container>
  );
};

export default CreateNewCampaign;
