import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const InvitePeopleViewContainer = styled.div`
  ${flex('column')};

  .invite-people-view {
    &__link-preview-title,
    &__code-title {
      font-weight: 600;
      font-size: ${theme.fontSizes.FONT_18};
      color: var(--SHADE_1_1);
      margin-bottom: ${theme.sizes.SIZE_12};
    }

    &__box {
      margin-top: ${theme.sizes.SIZE_16};
      margin-bottom: ${theme.sizes.SIZE_16};
    }

    &__pre-approved-disclaimer {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)}
      font-weight: 400;
      color: var(--SHADE_1_1);
    }
  }
`;
