import React, { memo, useContext, useMemo } from 'react';
import * as Styled from './LinkPreview.styles';
import { Button, Icon } from '@faxi/web-component-library';
import { InvitePeopleViews } from '../..';
import { useView } from 'providers/Views';
import { Image } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { DeepLinkData } from 'models/Organisation';
import { InvitePeopleContext } from 'store';

const LinkPreview = () => {
  const { t } = useTranslation();

  const { magicLink } = useContext(InvitePeopleContext);

  const rootLink = useMemo(() => {
    const link = magicLink?.deeplink?.alias ?? magicLink.code;
    return link.substring(0, link.lastIndexOf('/'));
  }, [magicLink.code, magicLink?.deeplink?.alias]);

  const initialDeepLinkData: DeepLinkData = useMemo(
    () => ({
      id: -1,
      alias: `${rootLink}/@${t('custom')}`,
      description: t('link_description-placeholder'),
      title: 'KINTO Join',
      name: rootLink,
      pre_approved: 0,
      organisation_id: 0,
      path: '',
    }),
    [rootLink, t]
  );

  const { alias, title, description } =
    magicLink?.deeplink ?? initialDeepLinkData;

  const { pushView } = useView<InvitePeopleViews>();

  return (
    <Styled.LinkPreviewContainer className="link-preview">
      <Image
        className="link-preview__image"
        src={'/assets/svg/kinto_join_logo_stacked_gradient.svg'}
        alt="link image"
        width="120"
      />
      <div className="link-preview__info">
        <p className="link-preview__info--custom-link">{alias}</p>
        <p className="link-preview__info--link">{rootLink}</p>
        <p className="link-preview__info--title">{title}</p>
        <p className="link-preview__info--description">{description}</p>
      </div>
      <Button
        onClick={() => pushView('customise-link')}
        className="link-preview__customise-btn"
        variant="ghost"
        icon={<Icon name={'pen'} />}
      >
        {t('global-customise')}
      </Button>
    </Styled.LinkPreviewContainer>
  );
};

export default memo(LinkPreview);
