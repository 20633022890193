import { FC, memo, useCallback } from 'react';
import {
  Button,
  Spinner,
  getColor,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';

import * as Styles from './CopyLink.styles';

export type CopyLinkProps = {
  className?: string;
  href: string;
  loading?: boolean;
  label?: string;
  newTab?: boolean;
  showEditPen?: boolean;
  onCopy?: () => void;
  onEdit?: () => void;
};

const CopyLink: FC<CopyLinkProps> = (props) => {
  const {
    className,
    href,
    label,
    loading,
    newTab = false,
    showEditPen = false,
    onCopy,
    onEdit,
  } = props;

  const { t } = useTranslation();
  const { showSnackBar } = useUtilities();

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(href);

      onCopy?.();

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('community_link_copy_to_clipboard'),
        variant: 'success',
      });
    } catch (e) {
      console.error(e);

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('dw_msg_error_general'),
        variant: 'error',
      });
    }
  }, [t, href, onCopy, showSnackBar]);

  return (
    <Styles.CopyLink className={classNames('kinto-copy-link', className)}>
      {label && <p className="kinto-copy-link__title">{label}</p>}

      <div className="kinto-copy-link__content">
        <div
          className="kinto-copy-link__content__invite"
          onClick={() => {
            if (!newTab) {
              copyToClipboard();
              return;
            }

            window.open(href, '_blank');
          }}
        >
          {href && !loading ? (
            href
          ) : (
            <Spinner
              size={18}
              color={getColor('--PRIMARY_1_1')}
              className="kinto-copy-link__content__invite__spinner"
            />
          )}
        </div>
        {onEdit && showEditPen && (
          <Button
            variant="ghost"
            icon={<Icon name="pen" />}
            aria-label={t('invite-people_title_edit_link')}
            className="kinto-copy-link__content__edit-button"
            onClick={onEdit}
          />
        )}
      </div>

      <Button
        variant="outline"
        onClick={copyToClipboard}
        className="kinto-copy-link__copy"
        icon={<Icon name="link" />}
      >
        {t('copy_link')}
      </Button>
    </Styles.CopyLink>
  );
};

export default memo(CopyLink);
