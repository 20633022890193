import { FC, memo, useEffect } from 'react';
import QRCode from 'qrcode';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Image } from '@faxi/web-component-library';

import * as Styled from './InvitePeopleCode.styles';

type InvitePeopleCodeProps = {
  className?: string;
  link: string;
  qrCodeUrl: string;
  mailToLink: string;
  downloadPDF: (...args: any[]) => Promise<void>;
  onGenerateCode: (value: string) => void;
};

const simulateMailto = (mailtoAddress: string) => {
  const link = document.createElement('a');

  link.href = mailtoAddress;
  const event = new MouseEvent('click');
  link.dispatchEvent(event);
  link.remove();
};

const InvitePeopleCode: FC<InvitePeopleCodeProps> = (props) => {
  const {
    className,
    link,
    qrCodeUrl,
    mailToLink,
    downloadPDF,
    onGenerateCode,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!link) return;

    const generateQrCode = async () => {
      const qrCode = await QRCode.toDataURL(link, {
        margin: 0,
        //set quality of generated image
        width: 350,
        type: 'image/jpeg',
      });

      onGenerateCode(qrCode);
    };

    generateQrCode();
  }, [link, onGenerateCode]);

  return (
    <Styled.InvitePeopleCode
      className={classNames('invite-people-code', className)}
    >
      <p className="invite-people-code__description">
        {t('invite-people_body_qr_code')}
      </p>

      <Image
        className="invite-people-code__qr-code"
        src={qrCodeUrl || ''}
        alt="qr code"
        fallbackUrl="/assets/svg/kinto_join_logo_stacked.svg"
      />
      <div className="invite-people-code__actions">
        <Button
          className="invite-people-code__actions--download"
          variant="outline"
          onClick={downloadPDF}
          icon={<Icon name={'download'} />}
        >
          {t('global-download')}
        </Button>
        <Button
          className="invite-people-code__actions--send"
          variant="primary"
          onClick={() => simulateMailto(mailToLink)}
          icon={<Icon name={'paper-plane-top'} />}
        >
          {t('send')}
        </Button>
      </div>
    </Styled.InvitePeopleCode>
  );
};

export default memo(InvitePeopleCode);
