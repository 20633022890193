import { useUtilities } from '@faxi/web-component-library';
import html2canvas from 'html2canvas';
import { t } from 'i18next';
import jsPDF from 'jspdf';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useCallbackAsync, useQuery } from '../../hooks';
import { APIResponseCustom, InviteCodes } from '../../models';
import { apiCommunity } from '../../modules';
import { createUrl } from '../../utils';
import AppContext from '../AppProvider/App.context';
import UserContext from '../UserProvider/User.context';
import InvitePeopleContext from './InvitePeople.context';

export type InvitePeopleProviderProps = PropsWithChildren<{
  organisationId: number;
}>;

const InvitePeopleProvider: FC<InvitePeopleProviderProps> = (props) => {
  const { children, organisationId } = props;

  const { platform } = useContext(AppContext);

  const { community } = useContext(UserContext);

  const { showSnackBar, hideSnackBar } = useUtilities();

  const downloadSnackBarId = useRef<string>('');

  const [qrCodeURL, setQrCodeUrl] = useState('');

  const [magicLink, setMagicLink] = useState<InviteCodes>({ code: '' });

  const magicCodeQuery = useCallback(
    async () => await apiCommunity.getMagicLink(organisationId),
    [organisationId]
  );

  const {
    data: fastCode,
    loading: loadingGet,
    setData: setFastCode,
  } = useQuery<APIResponseCustom<InviteCodes>, InviteCodes>({
    showSpinner: false,
    queryFn: magicCodeQuery,
    deps: [magicCodeQuery],
    onSuccess: ({ code, joincode, deeplink }) => {
      setMagicLink({
        code: createUrl(`${window.location.origin}/@${code}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
        deeplink: deeplink && {
          ...deeplink,
          pre_approved: +deeplink?.pre_approved,
        },
      });
    },
  });

  const [handleChangeJoinCode, loadingJoinCode] = useCallbackAsync({
    showSpinner: false,
    callback: async (join: boolean) => {
      const { joincode } = await apiCommunity.updateJoinCode(
        organisationId,
        join
      );

      setMagicLink((old) => ({
        code: createUrl(`${window.location.origin}/@${fastCode.code}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
        deeplink: old.deeplink,
      }));
    },
  });

  const [updateMagicLink, loadingUpdate] = useCallbackAsync({
    showSpinner: false,
    callback: async (newCode: string) => {
      await apiCommunity.updateMagicLink(organisationId, newCode);

      setMagicLink(({ joincode, deeplink }) => ({
        code: createUrl(`${window.location.origin}/@${newCode}`, {
          join: joincode,
          pid: platform?.id,
        }),
        joincode,
        deeplink,
      }));

      setFastCode({ code: newCode });

      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('link_saved'),
        variant: 'success',
      });
    },
  });

  const [downloadPDF] = useCallbackAsync({
    showSpinner: true,
    callback: async () => {
      downloadSnackBarId.current = showSnackBar(
        {
          text: t('waiting_download'),
          variant: 'loading',
          loadingText: t('downloading'),
        },
        { constant: true }
      );

      const pdf = new jsPDF('portrait', 'pt', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      pdf.setFontSize(24);

      const img = new Image(40, 40);
      img.src = community?.image_url || '/assets/images/users-icon.png';

      const yImagePosition = 150;
      const xImagePosition = (pageWidth - 40) / 2;

      img.onload = () => {
        pdf.addImage(img, 'png', xImagePosition, yImagePosition, 40, 40);
      };

      const communityElement = document.querySelector(
        '.kinto-community-element'
      ) as HTMLElement;

      if (communityElement) {
        const prevFontSize = communityElement.style.fontSize;
        const prevJustify = communityElement.style.justifyContent;

        communityElement.style.fontSize = '24px';
        communityElement.style.justifyContent = 'center';

        const spanElement = communityElement.querySelector(
          'span'
        ) as HTMLElement;
        const prevSpanMargin = spanElement.style.marginRight;
        spanElement.style.marginRight = '16px';

        const { width: commWidth, height: commHeight } =
          communityElement.getBoundingClientRect();

        const canvas = await html2canvas(communityElement as HTMLElement, {
          width: commWidth,
          height: commHeight + 12,
        });

        const communityImgData = canvas.toDataURL('image/jpeg', 1.0);

        const headerHtmlEl = document.createElement('div');
        const { width: headerWidth, height: headerHeight } =
          communityElement.getBoundingClientRect();
        headerHtmlEl.innerHTML = `
          <div style="font-size:24px; text-align:center; width: 100%;">
            <p style="font-family: 'ToyotaType'">${t(
              'invite-people_pdf_title'
            )}</p>
          </div>
        `;
        document.body.appendChild(headerHtmlEl);

        const inviteTextCanvas = await html2canvas(headerHtmlEl);
        const imgData = inviteTextCanvas.toDataURL('image/png');

        const xTextPosition = (pageWidth - headerWidth) / 2;
        const yTextPosition = yImagePosition + 40;

        pdf.addImage(
          imgData,
          'PNG',
          xTextPosition,
          yTextPosition,
          headerWidth,
          headerHeight
        );

        const xNamePosition = (pageWidth - commWidth) / 2;
        const yNamePosition = yTextPosition + 40;

        pdf.addImage(
          communityImgData,
          'JPEG',
          xNamePosition,
          yNamePosition,
          commWidth,
          commHeight + 12
        );

        const qrCodeHeight = 160;
        const xQrCodePosition = (pageWidth - qrCodeHeight) / 2;
        const yQrCodePosition = yNamePosition + 100;

        pdf.addImage(
          qrCodeURL,
          'base64',
          xQrCodePosition,
          yQrCodePosition,
          qrCodeHeight,
          qrCodeHeight
        );

        // revert changes
        communityElement.style.fontSize = prevFontSize;
        communityElement.style.justifyContent = prevJustify;
        spanElement.style.marginRight = prevSpanMargin;
        document.body.removeChild(headerHtmlEl);

        pdf.save('invite-people.pdf');
      }
    },
    onFinish: () => {
      hideSnackBar(downloadSnackBarId.current);
    },
  });

  return (
    <InvitePeopleContext.Provider
      value={{
        fastCode,
        qrCodeURL,
        magicLink,
        loadingGet,
        loadingUpdate,
        loadingJoinCode,
        setFastCode,
        downloadPDF,
        setQrCodeUrl,
        setMagicLink,
        updateMagicLink,
        handleChangeJoinCode,
      }}
    >
      {children}
    </InvitePeopleContext.Provider>
  );
};

export default InvitePeopleProvider;
