import { elevate_s, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const InvitePeopleCode = styled.div`
  ${flex('row')};

  width: 100%;
  padding: ${theme.sizes.SIZE_8} 0;

  border: 1px solid var(--SHADE_1_7);

  border-radius: ${theme.sizes.SIZE_8};

  padding: ${theme.sizes.SIZE_16};

  gap: ${theme.sizes.SIZE_8};

  .invite-people-code {
    &__description {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      font-weight: 400;

      color: var(--SHADE_1_1);

      margin: 0;
      margin-right: ${theme.sizes.SIZE_32};
    }

    &__qr-code {
      ${size(theme.sizes.SIZE_112)};
      ${elevate_s};

      margin-left: auto;
      padding: ${theme.sizes.SIZE_6};
      border-radius: ${theme.sizes.SIZE_8};
      border: 1px solid var(--SHADE_1_7);
    }

    &__actions {
      ${flex('column', 'center', 'center')};
      gap: ${theme.sizes.SIZE_10};

      &--download,
      &--send {
        height: ${theme.sizes.SIZE_48};
        padding: ${theme.sizes.SIZE_12};
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  ${phablet(css`
    ${flexGap(theme.sizes.SIZE_12, 'column')};
    flex-direction: column;

    .invite-people-code {
      &__qr-code {
        margin-left: unset;
      }
    }
  `)}
`;
