import { theme } from '@faxi/web-component-library';
import { flex, mobile } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const LinkPreviewContainer = styled.div`
  ${flex('row')};

  padding: ${theme.sizes.SIZE_16};

  border-radius: ${theme.sizes.SIZE_8};

  background-color: var(--BACKGROUND_2_1);

  margin-bottom: ${theme.sizes.SIZE_48};

  ${mobile(css`
    ${flex('column')};
    gap: ${theme.sizes.SIZE_12};
  `)}

  .link-preview {
    &__image {
      width: ${theme.sizes.SIZE_120};
      border-radius: ${theme.sizes.SIZE_8};
    }

    &__info {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_4};

      font-weight: 400;
      font-size: ${theme.fontSizes.FONT_14};
      color: var(--SHADE_1_1);

      margin-left: ${theme.sizes.SIZE_24};
      max-width: ${theme.sizes.SIZE_536};

      p {
        margin: 0;
      }

      &--custom-link {
        color: var(--PRIMARY_1_1);
        max-width: ${theme.sizes.SIZE_304};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--link {
        font-weight: 600;
      }
    }

    &__customise-btn {
      height: fit-content;
      margin-left: auto;
    }
  }
`;
