import { Modal, theme } from '@faxi/web-component-library';
import {
  abovePhablet,
  flex,
  fontSize,
  phablet,
  pxToRem,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const InvitePeopleModal = styled(Modal)`
  .wcl-modal {
    &__header {
      margin: 0 0 ${theme.sizes.SIZE_32};

      &__title {
        ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_24)};
        font-weight: 400;
      }
    }

    &__footer {
      ${flex('row')};

      gap: ${theme.sizes.SIZE_10};

      padding-top: ${theme.sizes.SIZE_16};

      .button {
        &:first-of-type {
          flex: 1;
        }
      }

      ${phablet(css`
        flex-direction: column;

        .button {
          width: 100%;
        }
      `)}
    }

    ${abovePhablet(css`
      width: ${pxToRem(theme.sizes.SIZE_712)};
    `)}
  }
`;
