import {
  ModalProps,
  ModalRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useView } from 'providers/Views';
import { InvitePeopleContext } from 'store';
import * as Styled from './InvitePeopleModal.styles';

export type InvitePeopleModalProps = {
  organisationId: number;
} & ModalProps;

const InvitePeopleModal: FC<InvitePeopleModalProps> = (
  props: InvitePeopleModalProps
): JSX.Element => {
  const { organisationId, triggerRef, ...rest } = props;

  const { t } = useTranslation();

  const { loadingGet, loadingUpdate } = useContext(InvitePeopleContext);

  const { activeView: ActiveView } = useView();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const closeModal = useCallback(() => {
    modal.close();
    triggerRef?.focus();
  }, [modal, triggerRef]);

  return (
    <Styled.InvitePeopleModal
      {...rest}
      ref={modalRef}
      className="invite-people-modal"
      loading={loadingGet || loadingUpdate}
      triggerRef={triggerRef}
      position="top"
      title={t('invite_people_to_community')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('invite_people_to_community'),
      })}
    >
      <ActiveView organisationId={organisationId} closeModal={closeModal} />
    </Styled.InvitePeopleModal>
  );
};

export default memo(InvitePeopleModal);
