import {
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Breadcrumbs,
  Button,
  Image,
  InfiniteScroll,
  SortDirections,
  SortOption,
  Spinner,
  Table,
  getColor,
  useCallbackRef,
  useEffectSkipFirst,
  useElementClass,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Icon, InfoCard, EmptyTab } from 'components';
import RewardModal from './components/RewardModal';
import { RewardType, REWARD_TYPES } from 'models';
import { apiGamification } from 'modules';
import { useColumnSettings, useHeadTitle, useInfinitePagination } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { TRANSLATION_KEYS } from './components/RewardModal/utils';
import { UserContext } from 'store';

const INITIAL_REWARDS_SORT = {
  sortBy: 'created_at',
  sortDirection: 'desc',
} as SortOption<RewardType>;

const REWARDS_PER_PAGE = 20;
const SCROLL_TOP_OFFSET = 20;
const DATE_STRING_REGEX = /\d{2}.\d{2}.\d{4}$/;

const Rewards: FC = () => {
  const { t } = useTranslation();

  const { communityId } = useContext(UserContext);

  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const navigate = useNavigate();
  const location = useLocation();

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const createModalBtnRef = useRef<HTMLButtonElement>(null);

  const [infiniteScroll, infiniteScrollRef] = useCallbackRef<HTMLDivElement>();
  const [editModalBtn, editModalBtnRef] = useCallbackRef<HTMLButtonElement>();

  const [showModal, setShowModal] = useState<'add' | 'edit' | 'none'>('none');
  const [selectedRewardId, setSelectedRewardId] = useState<number>(-1);

  useHeadTitle(
    `${t('group_settings')} - ${t('global-gamification')} - ${t(
      'account-rewards'
    )}`
  );

  const {
    columnBtnRef,
    columnTranslations,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  //To check when table container scroll is less then scroll offset
  const isScrollOnTop = infiniteScroll?.scrollTop < SCROLL_TOP_OFFSET;

  /*
   * Memoized value to check if loader needs to be shown in table container.
   * When location search includes sort params and scroll of table container is on top
   * then we need to show table loader
   */
  const showTableLoader = useMemo(
    () => !!(location.search.match(new RegExp('sort', 'i')) && isScrollOnTop),
    [isScrollOnTop, location.search]
  );

  useElementClass('#main', 'kinto-rewards', true);

  const {
    loading,
    currentPage,
    items: rewards,
    activeSort,
    setActiveSort,
    setItems: setRewards,
    handleContainerScroll,
  } = useInfinitePagination<RewardType, RewardType & { name: ReactNode }>({
    perPage: REWARDS_PER_PAGE,
    initialSortBy: INITIAL_REWARDS_SORT.sortBy,
    initialSortDirection: INITIAL_REWARDS_SORT.sortDirection,
    spinnerParent: '.kinto-page',
    deps: [communityId],
    resetDeps: [communityId],
    mappingFunction: async (rewards) => rewards.map(mapReward),
    apiRequest: async (
      offset: string,
      search?: string,
      sort?: keyof RewardType,
      direction?: SortDirections
    ) => {
      const {
        data: { rewards, total },
      } = await apiGamification.getRewardsPaginated(organisationId, {
        count: REWARDS_PER_PAGE,
        offset,
        sort,
        direction,
        ...(search && { search }),
      });

      return {
        data: rewards,
        total: total,
      };
    },
  });

  //When user clicks on edit actions here it will be saved as active reward
  const activeReward = useMemo(
    () => rewards.find((reward) => reward.id === selectedRewardId),
    [rewards, selectedRewardId]
  );

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        nameNode: t('global-reward_name'),
        description: t('dw_description'),
        redeem: t('campaigns-how_to_redeeem'),
        created_at: t('groupreg_js_created'),
      } as Record<Partial<keyof RewardType>, string>),
    [t]
  );

  const breadcrumbsLinks = useMemo(
    () => [
      {
        id: 'gamification-link',
        text: t('global-gamification'),
        href: `/community/${organisationId}/admin/gamification`,
      },
      {
        id: 'rewards-link',
        text: t('account-rewards'),
        href: `/community/${organisationId}/admin/gamification/rewards`,
      },
    ],
    [t, organisationId]
  );

  const mapReward = useCallback(
    ({ image, id, name, description, redeem, created_at, type }: RewardType) =>
      ({
        id,
        nameNode: (
          <div className="table-data-with-image">
            <Image
              className="reward-image"
              src={(image as string) || ''}
              alt={`${name} ${t('accessibility-reward_image')}`}
              fallbackUrl={
                type === REWARD_TYPES.PARKING_REWARD
                  ? '/assets/svg/square-parking.svg'
                  : '/assets/svg/gift-icon.svg'
              }
            />
            {typeof name === 'string'
              ? name
              : (name as ReactElement).props.children[1]}
          </div>
        ),
        description: description || '-',
        redeem: redeem || '-',
        created_at: DATE_STRING_REGEX.test(created_at)
          ? created_at
          : dayjs(created_at).format('DD.MM.YYYY'),
        name,
      } as RewardType),
    [t]
  );

  const handleEditAction = useCallback(
    (reward: RewardType, _: number, element: HTMLButtonElement) => {
      editModalBtnRef(element);

      setShowModal('edit');
      setSelectedRewardId(reward.id);
    },
    [editModalBtnRef]
  );

  const handleCloseModal = useCallback(() => {
    setShowModal('none');
  }, []);

  const handleSortOption = useCallback(
    (sortOption: SortOption<RewardType>) => {
      const { sortBy, sortDirection } = sortOption;
      setActiveSort(sortBy, sortDirection);
    },
    [setActiveSort]
  );

  const handleUpdateReward = useCallback(
    (reward: RewardType) => {
      const mappedReward = mapReward(reward);

      setRewards((oldRewards) => {
        if (!oldRewards.some((r) => reward.id === r.id)) {
          return [mappedReward, ...oldRewards];
        } else
          return oldRewards.map((r) => {
            if (reward.id === r.id) return mappedReward;
            else return r;
          });
      });
    },
    [mapReward, setRewards]
  );

  const handleDeleteReward = useCallback(
    async (rewardName: string, rid: string, element: HTMLButtonElement) => {
      try {
        const proceedWithAction = await prompts.delete({
          buttonIcon: undefined,
          confirmButtonText: t('Discovery_map_delete'),
          cancelButtonText: t('cancel'),
          confirmButtonVariant: 'delete-outline',
          triggerRef: element,
          title: t(TRANSLATION_KEYS.DELETE_DIALOG, {
            reward: rewardName,
          }),
        });

        if (!proceedWithAction) return Promise.resolve(false);

        showOverlay('.wcl-modal');

        await apiGamification.deleteReward(rid);

        setRewards((old) => old.filter((reward) => `${reward.id}` !== rid));

        snackBarSuccessMessage(
          t(TRANSLATION_KEYS.SUCCESSFULLY_DELETED, {
            reward: rewardName,
          })
        );

        return Promise.resolve(proceedWithAction);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal');
      }
    },
    [prompts, t, showOverlay, setRewards, hideOverlay]
  );

  const handleFileDelete = useCallback(
    (rid: string) => {
      setRewards((oldRewards) =>
        oldRewards.map((reward) =>
          `${reward.id}` === rid
            ? mapReward({ ...reward, image: null } as RewardType)
            : reward
        )
      );
    },
    [mapReward, setRewards]
  );

  /**
   * When loading is active then make sure that infinite container
   * is not scrollable to prevent user of scrolling while API is loading
   */
  useEffect(() => {
    if (!infiniteScroll) return;

    showTableLoader && loading
      ? (infiniteScroll.style.overflow = 'hidden')
      : infiniteScroll?.removeAttribute('style');
  }, [infiniteScroll, loading, showTableLoader]);

  // useInfinitePagination does not cover this case and I do not have the time to assess it at this moment
  useEffectSkipFirst(() => {
    if (loading) {
      showOverlay('.kinto-page');
    } else {
      hideOverlay('.kinto-page');
    }
  }, [communityId, loading]);

  return (
    <Fragment>
      <Breadcrumbs
        tabIndex={0}
        ref={breadcrumbsRef}
        aria-label="breadcrumbs"
        className="kinto-rewards__breadcrumbs"
        crumbs={breadcrumbsLinks}
      />

      {loading && currentPage === 1 && (
        <Spinner
          className="kinto-rewards__loader"
          color={getColor('--PRIMARY_1_1')}
          size={40}
        />
      )}

      {!rewards.length && !loading && (
        <EmptyTab
          ref={editModalBtnRef}
          icon="gift"
          actionTitle={t('gamification-rewards_button_create')}
          title={t('gamification-rewards_empty_state_title_do_you_want_to')}
          description={t(
            'gamification-rewards_empty_state_subtitle_create_rewards'
          )}
          onAction={() => setShowModal('add')}
        />
      )}

      {rewards.length > 0 && (
        <Fragment>
          <InfoCard
            text={t('gamification-rewards_alert_created_reward')}
            link={{
              label: t('gamification-rewards_button_go_to_campaign_page'),
              linkProps: {
                to: `/community/${organisationId}/admin/gamification/campaigns`,
              },
            }}
            button={{
              label: t('global-new_campaign'),
              buttonProps: {
                variant: 'outline',
                onClick: () => {
                  navigate(
                    `/community/${organisationId}/admin/gamification/campaigns/create-new`
                  );
                },
                icon: <Icon name="plus" />,
              },
            }}
            icon="megaphone"
            variant="announcement"
          />

          <div className="kinto-rewards__actions">
            <Button
              ref={createModalBtnRef}
              iconPosition="right"
              icon={<Icon name="plus" />}
              onClick={() => {
                setSelectedRewardId(-1);
                setShowModal('add');
              }}
            >
              {t('gamification-rewards_button_create')}
            </Button>

            <ColumnSettingsButton />
          </div>
        </Fragment>
      )}

      {rewards.length > 0 && (
        <InfiniteScroll
          ref={infiniteScrollRef}
          readyForScrolling={rewards.length > 0}
          loading={!showTableLoader && loading}
          className="kinto-rewards__infinite-scroll"
          onScroll={handleContainerScroll}
        >
          <Table<RewardType>
            tableId="rewards-table"
            cacheColumns
            rowsHaveAction
            rowsActionButtonIcon="pen"
            columnSettingsOpen={columnSettingsOpen}
            columnsModalLabels={columnTranslations}
            columnsBtnElement={columnBtnRef.current!}
            closeColumnModalAriaLabel={t('accessibility-button_close_modal')}
            rowsActionHeader={t('global-table_actions')}
            tableData={rewards}
            translationKeys={translationKeys}
            className="kinto-rewards__table"
            excludeSortColumns={['name', 'image', 'description', 'redeem']}
            initialSort={activeSort || INITIAL_REWARDS_SORT}
            excludeColumns={[
              'status',
              'type',
              'data',
              'file',
              'community_id',
              'updated_at',
              'name',
            ]}
            editActionAriaLabel={t('gamification-rewards_title_edit_reward')}
            sortIconAriaLabel={(property, orientation) =>
              t(
                orientation === 'desc'
                  ? 'accessibility-button_sort_ascending'
                  : 'accessibility-button_sort_descending',
                { property: property.toLowerCase() }
              )
            }
            onEditAction={handleEditAction}
            onColumnSortClicked={handleSortOption as any}
            onColumnsModalClose={closeColumnSettings}
          />
        </InfiniteScroll>
      )}

      {showModal !== 'none' && (
        <RewardModal
          showModal={showModal}
          ariaCloseModal={t('accessibility-button_close_modal', {
            name: t('gamification-rewards_button_create'),
          })}
          communityId={+organisationId}
          triggerRef={
            showModal === 'add'
              ? (createModalBtnRef.current as HTMLElement)
              : (editModalBtn as HTMLElement)
          }
          rewardId={`${activeReward?.id || ''}`}
          onClose={handleCloseModal}
          onSubmit={handleUpdateReward}
          onDelete={handleDeleteReward}
          onFileDelete={handleFileDelete}
        />
      )}
    </Fragment>
  );
};

export default Rewards;
