import {
  FC,
  ReactNode,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components';
import { debounce } from 'lodash';
import { useCallbackAsync } from 'hooks';

import * as Styled from './ReportsPDF.styles';

type PDFModalProps = ModalProps & { pages: ReactNode[] };

const ReportsPDF: FC<PDFModalProps> = (props) => {
  const { pages } = props;

  const { t } = useTranslation();

  const pagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const observers = useRef<IntersectionObserver[]>([]);
  const lastScrollTop = useRef(0);
  const scrollDirection = useRef('downwards');
  const pageVisibilities = useRef<boolean[]>([]);
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [generatePDF, isPDFGenerating] = useCallbackAsync({
    callback: async () => {
      const pdf = new jsPDF('portrait', 'pt', 'a4');

      const sheet = new CSSStyleSheet();
      sheet.replaceSync(
        '.wcl-switch__switch__slider::before {transition: none !important;}'
      );
      document.adoptedStyleSheets = [sheet];

      for (let i = 0; i < pagesRef.current.length; ++i) {
        const canvas = await html2canvas(pagesRef.current[i]!, {
          scale: 2,
          useCORS: true,
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        const ratio = canvas.height / canvas.width;
        const width = pdf.internal.pageSize.getWidth();
        const height = width * ratio;

        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);

        if (i !== pagesRef.current.length - 1) {
          pdf.addPage();
        }
      }

      pdf.save(`report_${dayjs().format('DD.MM.YYYY.')}.pdf`);

      sheet.replaceSync(
        '.wcl-switch__switch__slider::before {transition: all 0.4s ease 0s;}'
      );
    },
    showSpinner: true,
    spinnerParent: '.wcl-modal',
  });

  const pdfHeader = useMemo(
    () => (
      <div className="kinto-pdf__page__header">
        <img
          src="/assets/svg/kinto_join.svg"
          className="kinto-pdf__page__header__logo"
          alt=""
        />
      </div>
    ),
    []
  );

  const determineScrollDirection = debounce(() => {
    if (!modal?.main) return;

    const currScroll = modal.main.scrollTop;

    scrollDirection.current =
      currScroll > lastScrollTop.current ? 'downwards' : 'upwards';

    if (
      scrollDirection.current === 'downwards' &&
      currentPage &&
      currentPage < pageVisibilities.current.length &&
      pageVisibilities.current[currentPage]
    ) {
      setCurrentPage(currentPage + 1);
    }

    if (
      scrollDirection.current === 'upwards' &&
      currentPage &&
      currentPage > 1 &&
      pageVisibilities.current[currentPage - 2]
    ) {
      setCurrentPage(currentPage - 1);
    }

    lastScrollTop.current = Math.max(0, currScroll);
  }, 50);

  // scroll listener to determine scroll direction
  useEffect(() => {
    if (!modal?.main) return;

    modal.main.addEventListener('scroll', determineScrollDirection);

    return () => {
      modal.main.removeEventListener('scroll', determineScrollDirection);
    };
  }, [modal, determineScrollDirection]);

  // set current page and page visibilities after dom rendered
  useEffectOnceWhen(() => {
    setTimeout(() => {
      setNumberOfPages(pagesRef.current.length);

      pageVisibilities.current = Array.from(
        { length: pagesRef.current.length },
        () => false
      );
    }, 0);
  });

  // observe intersections to determine current page
  useEffect(() => {
    const observersLocal = observers.current;

    setTimeout(() => {
      const options = {
        root: null,
        rootMargin: '0px',
      };

      for (let i = 0; i < pagesRef.current.length; ++i) {
        const observer = new IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0];

          pageVisibilities.current[i] = isIntersecting;

          if (isIntersecting) {
            // the element has just appeared in the viewport
            setCurrentPage(i + 1);
          } else {
            // a change occurred, an element has been scrolled away from the viewport
            // depending on the scroll direction, set next visible page as current
            // 'any' is due to issues with tests... (jest)
            setCurrentPage(
              (pageVisibilities.current as any[])[
                scrollDirection.current === 'downwards'
                  ? ('findIndex' as any)
                  : ('findLastIndex' as any)
              ](Boolean) + 1
            );
          }
        }, options);

        observersLocal.push(observer);

        observer.observe(pagesRef.current[i]!);
      }
    }, 0);

    return () => {
      observersLocal.forEach((o) => o.disconnect());
    };
  }, []);

  return (
    <Styled.Container
      ref={modalRef}
      closeOnEscape={!isPDFGenerating}
      onOverlayClick={() => (modal.skipClosing.current = isPDFGenerating)}
      title={t('global-button_pdf_preview')}
      className="kinto-pdf"
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t(`global-button_pdf_preview`),
      })}
      footer={
        <div className="kinto-pdf__footer">
          <div className="kinto-pdf__footer__pagination">
            {t('sustainability-pdf_page', {
              page: currentPage,
              total: numberOfPages,
            })}
          </div>
          <Button
            icon={<Icon name="download" />}
            onClick={() => {
              generatePDF();
            }}
          >
            {t('sustainability-button_download_report')}
          </Button>
        </div>
      }
      {...props}
    >
      {pages.map((p, index) => (
        <div
          key={index}
          className="kinto-pdf__page"
          ref={(el) => {
            pagesRef.current[index] = el;
          }}
        >
          {pdfHeader}

          <div className="kinto-pdf__page__main">{p}</div>
        </div>
      ))}
    </Styled.Container>
  );
};

export default memo(ReportsPDF);
