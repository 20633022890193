import { Button } from '@faxi/web-component-library';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Parking } from 'models';

import * as Styled from './CarParkElement.styles';

type CarParkElementProps = {
  carpark: Parking;
  onRaiseBarrier: MouseEventHandler<HTMLButtonElement>;
  onClick?: () => void;
};

const CarParkElement = (props: CarParkElementProps) => {
  const { carpark, onRaiseBarrier, onClick } = props;

  const { t } = useTranslation();

  return (
    <Styled.CarParkElement onClick={onClick}>
      <div className="carpark__title">
        {carpark.name}

        {/* TODO: uncoment this when parking management is ready */}
        {/* <Icon name="chevron-right" /> */}
      </div>

      {/* TODO: uncoment this when parking management is ready */}
      {/* <div className="carpark__details">
        <Icon name="location-dot" />
        {carpark.address}
      </div>

      <div className="carpark__details">
        <Icon name="square-parking" />
        {t('parking_management-carpark_number_of_parking_spaces', {
          number: carpark.capacity,
        })}
      </div> */}
      <Button onClick={onRaiseBarrier} className="carpark__raise-barrier-btn">
        {t('parking-button_raise_a_barrier')}
      </Button>
    </Styled.CarParkElement>
  );
};

export default CarParkElement;
